import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";

import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import Transaction_Facilitation_Terms from "../../views/Transaction_Facilitation_Terms";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { useCompanyStepDetails } from "../../Hooks/Auth";
import axios from "axios";

const ReGenerateMandate = () => {
  const [mandateTokenId, setMandateTokenId] = useState("");
  const [merchantId, setMerchantId] = useState("");
  const [rData, setRData] = useState("");
  const [html, setHtml] = useState("");
  const [check, setCheck] = useState();
  const { mutate, isLoading } = useCompanyStepDetails();

  const AcceptTerms = async () => {
    await fetchPaymentForm();
  };

  const fetchPaymentForm = async () => {
    try {
      const res = await axios.post("enach_mandate/re_generate_mandate_token");
      const mandate = res?.data?.mandateTokenParams;

      setHtml(res?.data?.formHTML);
      setMandateTokenId(mandate?.mandate_tokenid);
      setMerchantId(mandate?.mercid);
      setRData(mandate?.links?.at(1)?.parameters?.rdata);
    } catch (error) {
      console.error("Error fetching payment form:", error);
    }
  };

  const handleSubmit = () => {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://pay.billdesk.com/web/v1_2/embeddedsdk";
    form.target = "_blank";

    const mandateTokenIdInput = document.createElement("input");
    mandateTokenIdInput.type = "hidden";
    mandateTokenIdInput.name = "mandatetokenid";
    mandateTokenIdInput.value = mandateTokenId;
    form.appendChild(mandateTokenIdInput);

    const merchantIdInput = document.createElement("input");
    merchantIdInput.type = "hidden";
    merchantIdInput.name = "merchantid";
    merchantIdInput.value = merchantId;
    form.appendChild(merchantIdInput);

    const rDataInput = document.createElement("input");
    rDataInput.type = "hidden";
    rDataInput.name = "rdata";
    rDataInput.value = rData;
    form.appendChild(rDataInput);

    document.body.appendChild(form);
    form.submit();
  };

  const { data: loggedInCompanyData, isLoading: companyLoading } =
    useGetLoggedInUser();

  const declineTerms = () => {
    setOpenSecond(true);
  };

  const currentDate = new Date().toDateString("dd/MM/yyyy");
  const currentTime = new Date().toLocaleTimeString();
  return (
    <Paper elevation={0} sx={{ bgcolor: "transparent" }}>
      <BreadCrumbHeader MainText={"Mandate Sign"} showbreadcrumb={true} />
      <Box
        sx={{
          padding: "20px 0px",
          backgroundColor: "#fff",
          borderRadius: "20px",
        }}
      >
        <Transaction_Facilitation_Terms
          Date={currentDate}
          Time={currentTime}
          Place={loggedInCompanyData?.data?.CompanyAddress?.City}
        />
        <Box
          sx={{
            maxWidth: "65%",
            mx: "auto",
          }}
        >
          {mandateTokenId ? null : (
            <Typography sx={CommonTypoStyle}>
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: "25px" },
                  padding: "1px",
                }}
                onClick={() => {
                  setCheck(!check);
                }}
              />
              Yes, I understand and agree to the Rules and Regulations of the
              Trading, Privacy Policy, BXI Policies, and EAAA Agreement (By
              agreeing to EAAA Agreement I agree to hold my funds in Escrowpay's
              account willingly and consent for the same). I hereby acknowledge
              and am fully aware that I am legally obligated and bound by the
              aforementioned legal documents.
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: "20px",
          }}
          gap={2}
        >
          {mandateTokenId ? null : (
            <>
              <Button
                variant="outlined"
                size="large"
                sx={DeclineTypoStyle}
                onClick={declineTerms}
              >
                <Typography sx={CommonTypoStyle}>Decline</Typography>
              </Button>
              <Button
                variant="outlined"
                disabled={check ? false : true}
                size="large"
                sx={ProgressTypoStyle}
                onClick={AcceptTerms}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Typography
                    sx={{
                      ...AgreeTypoStyle,
                      color: check ? "#EBEDEE" : "#445FD2",
                    }}
                  >
                    Agree
                  </Typography>
                )}
              </Button>
            </>
          )}

          {mandateTokenId ? (
            <Button
              onClick={handleSubmit}
              sx={{
                width: "200px",
                height: "50px",
                background: "#445FD2",
                borderRadius: "10px",
                textTransform: "none",
                boxShadow: "none",
              }}
            >
              {" "}
              <Typography sx={SignMandateStyle}>Sign Mandate</Typography>
            </Button>
          ) : null}
        </Box>
      </Box>
    </Paper>
  );
};

export default ReGenerateMandate;
const SignMandateStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
  textAlign: "center",
  textTransform: "none",
  color: "#fff",
};

const DeclineTypoStyle = {
  borderRadius: "10px",
  textTransform: "none",
  boxShadow: "none",
  width: {
    xl: "15.7rem",
    lg: "15.7rem",
    md: "10rem",
    sm: "5rem",
    xs: "5rem",
  },
  height: {
    xl: "5.4rem",
    lg: "5.4rem",
    md: "5rem",
    sm: "3rem",
    xs: "3rem",
  },
};

const CommonTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15px",
  lineHeight: "22px",
  color: "#7D8BA6",
};

const ProgressTypoStyle = {
  color: "white",
  border: "1px solid #EBEDEE",
  background: "#445FD2",
  borderRadius: "10px",
  width: {
    xl: "15.7rem",
    lg: "15.7rem",
    md: "10rem",
    sm: "5rem",
    xs: "5rem",
  },
  height: {
    xl: "5.4rem",
    lg: "5.4rem",
    md: "5rem",
    sm: "3rem",
    xs: "3rem",
  },
  ":hover": {
    background: "#445FD2",
    color: "#fff",
  },
};

const AgreeTypoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1rem",
    sm: "1rem",
    xs: "1rem",
  },
  textAlign: "center",
  textTransform: "none",
};
