import axios from "axios";

import { useQuery } from "react-query";

const useGetOrderDataById = (orderId) => {
  const getOrderDataById = async () => {
    const { data, isLoading, error, refetch } = await axios.get(
      `purchase/get_purchase_order_by_id/${orderId}`
    );
    return {
      data,
      isLoading,
      error,
      refetch,
    };
  };

  return useQuery(["purchase_order"], getOrderDataById);
};

export default useGetOrderDataById;
