import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import CommaSeparator from "../../components/CommaSeprator";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useProductAddToCart } from "../../Hooks/ProductActions/useProductAddToCart";
import Weight from "../../assets/Images/Weight.svg";
import BXITokenIcon from "../../assets/Stack of Coins.svg";
import CarouselforApperal from "../../components/Carousel/CarouselforApperal";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import PreviewPageHeader from "../../components/PreviewPageHeader";
import { GetProductByIdAction } from "../../redux/action/ProductActions/GetProductByIdAction";
// import axios from "axios";
import { useAddToWishlist } from "../../Hooks/ProductActions/useAddToWishlist";
import { useGetCartData } from "../../Hooks/ProductActions/useGetCartData";
import { useRemoveWishlistProductByProductId } from "../../Hooks/ProductActions/useRemoveWishlistProduct";

import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import useGetProductById from "../../Hooks/ProductActions/useGetProductById";
import SelectedImg from "../../assets/Images/CommonImages/Selected.png";
import UnSelectedImg from "../../assets/Images/CommonImages/Unselected.png";
import CarouselForProductHorizontal from "../../components/Carousel/CarouselForProductHorizontal";
import CommaSeprator from "../../components/CommaSeprator";
import PageLoader from "../../components/LoadingButton/PageLoader";
import { ProductAnalysisUpdate } from "../../redux/action/Products/ProductAnalysis";
import GlobalToast from "../../components/Toasts/GlobalToast";
import KeyFeatures from "./Components/KeyFeatures";
import PackagingAndDelivery from "./Components/PackagingAndDelivery";
import InstructionsToUseProduct from "./Components/InstructionsToUseProduct";
import PackagingInfor from "./Components/PackagingInfor";
import TechnicalInfor from "./Components/TechnicalInfor";
import PickUplocations from "./Components/PickUplocations";
import AdditionalCost from "./Components/AdditionalCost";
import ManufacturingAndExpiry from "./Components/ManufacturingAndExpiry";
import SampleDetails from "./Components/SampleDetails";
import ProductDescriptions from "./Components/ProductDetails";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
function DiscountedPrice({ regularPrice, discountPrice, percentage }) {
  const discount = regularPrice - discountPrice;
  const discountPercent = (discount / regularPrice) * 100;
  const formattedDiscountPercent = discountPercent.toFixed(2);
  const gstPrice = discountPrice / (1 + percentage / 100);
  const gstamount = discountPrice - gstPrice;
  const formattedgstPrice = gstPrice?.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const formattedgstamount = gstamount?.toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "auto",
          mt: "10px",
          marginBottom: "-11px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: "18px",
            lineHeight: "36px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            mt: 0.2,
            color: "#DC3737",
          }}
        >
          {formattedDiscountPercent > 0 && (
            <div>-{formattedDiscountPercent}%</div>
          )}{" "}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "36px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#6B7A99",
          }}
        >
          &nbsp;{discountPrice}
          &nbsp;({formattedgstPrice}
          <img
            src={BXITokenIcon}
            style={{
              width: "20px",
              height: "auto",
            }}
            alt="BXI Token"
          />{" "}
          + {formattedgstamount}₹
          <span
            style={{
              fontSize: "15px",
              fontWeight: 400,
            }}
          >
            GST
          </span>
          )
        </Typography>
      </Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "36px",
          letterSpacing: "0.06em",
          textTransform: "capitalize",

          color: "#6B7A99",
          textDecoration: "line-through",
        }}
      >
        {formattedDiscountPercent > 0 && (
          <div>
            MRP: <CommaSeprator Price={regularPrice} />
          </div>
        )}
      </Typography>
      <br />
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "15px",
          lineHeight: "10px",
          textTransform: "none",
          color: "#6B7A99",
        }}
      >
        All prices are inclusive of Taxes
      </Typography>
    </div>
  );
}

const ProductDetails = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  let ProductId = id;
  const dispatch = useDispatch();
  const [value, setValue] = React.useState("1");
  const [currentImage, setCurrentImage] = useState(0);
  const [GetProductByIdData, setGetProductByIdData] = useState();
  const [showSizechart, setShowSizechart] = useState(false);
  const [storeTechnicalInfo, setStoreTechnicalInfo] = useState();
  const [VariationToMap, setVariationToMap] = useState();
  const [WishlistData, setWishlistData] = useState();
  const [sticky, setSticky] = useState("");

  const [IsSample, setIsSample] = useState("");
  const [StoreProductSize, setProductSize] = useState("");
  const [CustomQuantity, setCustomQty] = useState(0);
  const [color, setColor] = useState("");
  const { data: cartItems, refetch: cartRefetch } = useGetCartData();
  const [storeVariationData, setStoreVariationData] = useState();
  const [borderColor, setBorderColor] = useState(true);
  const [VariationAllData, setVariationAllData] = useState();
  const [addToCardView, setAddToCardView] = useState(false);

  let minValue = 0;
  let maxValue = 0;

  const {
    data: Productdata,
    isLoading: DataLoading,
    error: DataError,
    refetch: ProductRefetch,
  } = useGetProductById(id);

  const {
    data: CompanyTypeData,
    isLoading: CompanyTypeLoading,
    error: CompanyTypeError,
    refetch: CompanyTypeRefetch,
  } = useGetCompanyTypeData(Productdata?.ProductType);

  async function GetProductByid() {
    await axios
      .get(`product/get_product_byId/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setGetProductByIdData(res?.data);
        setStoreTechnicalInfo(res?.data?.ProductFeatures);
        setStoreVariationData(res?.data?.ProductsVariantions[0]);
      });
  }
  useEffect(() => {
    GetProductByid();
  }, []);

  const [like, setLike] = useState(false);
  let ColorData = {};
  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (index === 0) {
      ColorData = item;
    }
  });

  const { data: mutateCartData, mutate: addtocart } = useProductAddToCart();

  const technicalinfo = [
    {
      name: "Before Weight",
      img: Weight,
      val: GetProductByIdData?.ProductTechInfo?.WeightBeforePackingPerUnit,
      measure: GetProductByIdData?.WeightBeforePackingPerUnitMeasurUnit,
    },
  ];
  const ImageDataArray = GetProductByIdData?.ProductImages;

  useEffect(() => {
    dispatch(GetProductByIdAction(ProductId));
  }, [dispatch]);

  let samplestate = false;
  GetProductByIdData?.ProductsVariantions?.map((item, index) => {
    if (item.SampleAvailability) {
      return (samplestate = true);
    } else {
      return (samplestate = false);
    }
  });

  const topElementRef = useRef(null);

  useEffect(() => {
    if (topElementRef.current) {
      topElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  useEffect(() => {
    ColorData = {};
  }, [color, storeVariationData]);

  useEffect(() => {
    if (mutateCartData) {
      toast.success("Added to cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [mutateCartData]);

  let NewdataArray = [];

  for (let i = 0; i < GetProductByIdData?.ProductsVariantions?.length; i++) {
    let ProductColor = GetProductByIdData?.ProductsVariantions[i].ProductColor;
    let finddata = NewdataArray.find((d) => d.ProductColor === ProductColor);
    if (finddata) {
      finddata.ProductVariations.push(
        GetProductByIdData?.ProductsVariantions[i]
      );
    } else {
      NewdataArray.push({
        ProductColor: GetProductByIdData?.ProductsVariantions[i].ProductColor,
        ProductVariations: [GetProductByIdData?.ProductsVariantions[i]],
      });
    }
  }

  useEffect(() => {
    GetProductByid();
  }, []);

  useEffect(() => {
    if (IsSample) {
      setCustomQty(
        Number(
          GetProductByIdData?.ProductsVariantions?.at(0)?.SampleAvailability ||
            GetProductByIdData?.ProductsVariantions?.at(1)?.SampleAvailability
        )
      );
    } else {
      setCustomQty(0);
    }
  }, [IsSample]);

  const {
    data: mutateWishlistData,
    mutate: addtowishlist,
    isLoading: wishlistMutateLoading,
    error: wishlistMutateError,
  } = useAddToWishlist();

  const { data: mutateRemoveWishlistData, mutate: removefromwishlist } =
    useRemoveWishlistProductByProductId();

  const { data: AuthUserData } = useGetAuthUser();

  async function fetWishlistData() {
    await axios
      .get("wishlist/get_wishlist_product", {
        withCredentials: true,
      })
      .then((res) => {
        setWishlistData(res?.data);
      });
  }

  useEffect(() => {
    fetWishlistData();
  }, []);

  let priceone;
  let price;
  GetProductByIdData?.ProductsVariantions?.forEach((item) => {
    if (item?._id === storeVariationData) {
      price = item.PricePerUnit;
      return price;
    }
  });
  priceone = storeVariationData?.PricePerUnit
    ? storeVariationData?.PricePerUnit
    : price;

  let oneone;
  GetProductByIdData?.ProductsVariantions?.forEach((item) => {
    if (item?._id === storeVariationData) {
      oneone = item.DiscountedPrice;
      return oneone;
    }
  });

  let priceTwo = storeVariationData?.DiscountedPrice
    ? storeVariationData?.DiscountedPrice
    : oneone;

  GetProductByIdData?.ProductsVariantions?.map((res, idx) => {
    if (res?._id === storeVariationData) {
      maxValue = res?.MaxOrderQuantity;
      return res.MaxOrderQuantity;
    }
  });

  let ObjectForAddToCart = {
    ProductVariationId: storeVariationData,
    IsSample: IsSample ? true : false,
    ProductQty: CustomQuantity,
    ProductId: ProductId,
    IsMedia: false,
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  useEffect(() => {
    GetProductByIdData?.ProductsVariantions?.map((res, idx) => {
      if (res?._id === storeVariationData) {
        setStoreVariationData(res?._id);
        setVariationAllData(res);
        return res?._id;
      }
    });
  }, [storeVariationData]);

  async function handleAddToCart() {
    if (
      AuthUserData?.data?.rightsarray?.includes("purchase") ||
      AuthUserData?.data?.rightsarray?.includes("finance") ||
      AuthUserData?.data?.superAdmin
    ) {
      if (!StoreProductSize) {
        toast.error("Please Select Size to continue", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      if (!IsSample && CustomQuantity < minValue) {
        <GlobalToast
          ToastMessage={`Entered quanity is less than Min Order Quantity ${VariationAllData?.MinOrderQuantity}`}
          type={"error"}
        />;

        toast.error(
          `Entered quanity is less than Min Order Quantity ${VariationAllData?.MinOrderQuantity}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else if (!IsSample && CustomQuantity > maxValue) {
        toast.error(
          `Entered quanity is Greater than Max Order Quantity ${VariationAllData?.MaxOrderQuantity}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      } else if (!IsSample && CustomQuantity === 0) {
        toast.error("Please Select Qty to continue", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        await axios
          .post(
            "product/add_to_cart_from_marketplace",
            { ObjectForAddToCart },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            cartRefetch();
            toast.success("Added to Cart", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            dispatch(
              ProductAnalysisUpdate(ProductId, "", "ProductAddToCardCount")
            );
          })
          .catch((error) => {});
      }
    } else {
      toast.error("You are not authorized to access cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  }

  async function duplicateCartItem() {
    if (cartItems?.at(0)?._id === cartItems?.at(0)?._id) {
      toast.success("Already Item added to the cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        navigate("/home/cart", {
          state: 0,
        });
      }, 3000);
    }
  }

  function getSizeCategory(ProductSize) {
    if (!ProductSize) {
      return "Sizes";
    }

    const includesAny = (str, units) =>
      units.some((unit) => str.includes(unit));

    if (
      includesAny(ProductSize, ["ml", "oz", "l", "cu ft", "g", "kg", "lbs"])
    ) {
      return "Volume";
    } else if (includesAny(ProductSize, ["months", "Years", "days"])) {
      return "Shelf Life";
    } else if (includesAny(ProductSize, ["C", "F"])) {
      return "Temperature";
    } else if (includesAny(ProductSize, ["mAh", "Ah", "Kwh"])) {
      return "BatteryCapacity";
    } else if (
      includesAny(ProductSize, ["g", "%DV", "tonne", "kg", "mlg", "mig"])
    ) {
      return "Nutrition";
    } else if (includesAny(ProductSize, ["W", "KW", "HP", "V", "A"])) {
      return "PowerConsumption";
    } else if (includesAny(ProductSize, ["PX", "DPI", "PPI", "LPI"])) {
      return "Resolutions";
    } else if (includesAny(ProductSize, ["Hz", "KHz", "MHz", "GHz"])) {
      return "Frequency";
    } else if (includesAny(ProductSize, ["psi", "kPa"])) {
      return "Pressure";
    } else if (ProductSize.includes("DB")) {
      return "Amplification";
    } else if (ProductSize.includes("cal")) {
      return "CalorieCount";
    } else if (ProductSize.includes("%")) {
      return "Humidity";
    } else {
      return "Sizes";
    }
  }

  const handleDownloadSizechart = () => {
    const url = Productdata?.SizeChart[0]?.url;
    if (url) {
      const img = document.createElement("img");
      img.src = url;
      img.alt = "Size Chart";

      const newTab = window.open();
      newTab.document.body.appendChild(img);
    } else {
    }
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  if (Productdata === undefined) {
    return <PageLoader />;
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/product/GetOwnProducts?productId=${id}`,
        {
          withCredentials: true,
        }
      );
      setAddToCardView(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  fetchData();

  return (
    <React.Fragment>
      <Paper
        ref={topElementRef}
        elevation={0}
        sx={{ bgcolor: "transparent", boxShadow: "none" }}
      >
        <BreadCrumbHeader
          MainText={CompanyTypeData?.data?.CompanyTypeName}
          showbreadcrumb={true}
        />

        <Paper
          elevation={0}
          sx={{
            borderRadius: "20px",
            mt: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              width: "95%",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                height: "70px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PreviewPageHeader />
            </Box>
          </Box>
          {CompanyTypeData?.data?.CompanyTypeName === "Textile" ||
          CompanyTypeData?.data?.CompanyTypeName === "Lifestyle" ||
          CompanyTypeData?.data?.CompanyTypeName === "Office Supply" ||
          CompanyTypeData?.data?.CompanyTypeName === "Others" ||
          !CompanyTypeData?.data?.CompanyTypeName ? (
            <Grid
              container
              sx={{
                width: "90%",
                mx: "auto",
                mt: 0,
                display: "flex",
                gap: "20px",
              }}
            >
              <Grid
                item
                xl={5}
                lg={5}
                md={12}
                sm={12}
                xs={12}
                sx={{ ...fixGrid, mt: -6 }}
              >
                <CarouselforApperal ImageDataArray={ImageDataArray} />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={12}
                sm={12}
                xs={12}
                sx={{ ...fixGrid, mt: 0 }}
              >
                <Box sx={{ marginTop: "0px" }}>
                  <Typography sx={{ ...semi, fontSize: "20px" }}>
                    {GetProductByIdData?.ProductName}
                  </Typography>
                  <Typography
                    sx={{
                      ...semi,
                      color: "#6B7A99",
                      textAlign: "justify",
                      fontSize: "14px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                    }}
                  >
                    {GetProductByIdData?.SellerCompanyName}
                  </Typography>
                  <Box sx={{ mt: 3 }}>
                    <DiscountedPrice
                      regularPrice={priceone}
                      discountPrice={priceTwo}
                      percentage={
                        GetProductByIdData &&
                        GetProductByIdData?.ProductsVariantions?.length > 0 &&
                        GetProductByIdData?.ProductsVariantions[0]?.GST
                      }
                    />
                  </Box>

                  <Box sx={{ display: "flex", gap: "70px", mt: 1 }}>
                    {GetProductByIdData?.ProductCategoryName === "QSR" ||
                    GetProductByIdData?.ProductCategoryName ===
                      "FMCG" ? null : (
                      <>
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "grey",
                              mt: 2,
                            }}
                          >
                            Available Colors
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: {
                                x: "flex-start",
                                lg: "flex-start",
                                md: "flex-start",
                                sm: "flex-start",
                                xs: "center",
                              },
                              width: "100%",
                              overflow: "auto",
                              "::-webkit-scrollbar": {
                                mt: 1,
                                display: "flex",
                                height: "4px",
                              },
                            }}
                          >
                            {GetProductByIdData &&
                              NewdataArray?.map((res, idx) => {
                                return (
                                  <Box
                                    key={idx}
                                    onClick={() => {
                                      setVariationToMap(res?.ProductVariations);
                                      setBorderColor(res?.ProductColor);
                                    }}
                                    sx={{
                                      background: res?.ProductColor,
                                      width: {
                                        xl: "25%",
                                        lg: "25%",
                                        md: "25%",
                                        sm: "5%",
                                        xs: "13%",
                                      },
                                      mb: 1,
                                      ml: 0,
                                      mt: 1,
                                      height: "100%",
                                      minHeight: "30px",
                                      maxWidth: "25%",
                                      minWidth: "25%",
                                      borderRadius: "0.5rem",
                                      cursor: "pointer",
                                      transition:
                                        res?.ProductColor === borderColor
                                          ? "0.2s linear"
                                          : null,
                                      boxShadow:
                                        res?.ProductColor === borderColor
                                          ? "5px 5px 5px grey"
                                          : null,

                                      border:
                                        res?.ProductColor === borderColor
                                          ? "2px solid #000"
                                          : "2px solid lightgray",
                                    }}
                                  ></Box>
                                );
                              })}
                          </Box>
                        </Box>
                      </>
                    )}
                    <Box>
                      {GetProductByIdData?.gender === null ||
                      GetProductByIdData?.gender === undefined ? null : (
                        <Box>
                          <Typography
                            sx={{
                              ...semiSub,
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "grey",
                            }}
                          >
                            Gender
                          </Typography>
                          <Typography
                            sx={{
                              ...tableData,
                              textAlign: "start",
                              lineHeight: "3rem",
                              fontSize: "14px",
                              color: "grey",
                              fontWeight: 600,
                            }}
                          >
                            {GetProductByIdData?.gender}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>

                  <Box
                    mt={1}
                    sx={{
                      width: "100%",
                      mx: "auto",
                      overflow: "hidden",
                    }}
                  >
                    <TableContainer sx={{ overflow: "hidden" }}>
                      <Table sx={{ overflow: "hidden" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ borderBottom: "none" }}>
                              <Typography
                                sx={{
                                  marginLeft: "-15px",
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "grey",
                                }}
                              >
                                {getSizeCategory(
                                  GetProductByIdData?.ProductsVariantions[0]
                                    ?.ProductSize
                                )}
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                width: "90px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "grey",
                                }}
                              >
                                Min QTY
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ borderBottom: "none", width: "90px" }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "grey",
                                }}
                              >
                                Max QTY
                              </Typography>
                            </TableCell>
                            <TableCell
                              sx={{ borderBottom: "none", width: "100px" }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  lineHeight: "20px",
                                  color: "grey",
                                }}
                              >
                                GST{" "}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{}}>
                            <TableCell
                              sx={{
                                borderBottom: "none",
                                width: "150px",
                                height: "30px",
                                p: 0,
                              }}
                            >
                              <Select
                                sx={{
                                  width: "140px",
                                  marginLeft: "0px",
                                  marginRight: "auto",
                                  height: "30px",
                                  marginTop: "-10px",
                                  border: "1px solid #8C8C8C",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "transparent",
                                    },
                                }}
                                defaultValue={"hello"}
                                onChange={(e) => {
                                  setStoreVariationData(e.target.value);
                                  setIsSample(false);
                                  setProductSize(e.target.value);
                                }}
                              >
                                <MenuItem disabled>
                                  <Typography
                                    sx={{
                                      cursor: "pointer",
                                      color: "black",
                                      fontSize: "14px",
                                    }}
                                  >
                                    Select a Size
                                  </Typography>
                                </MenuItem>
                                {!VariationToMap && NewdataArray?.length > 0
                                  ? !VariationToMap &&
                                    NewdataArray[0]?.ProductVariations?.map(
                                      (el, idx) => {
                                        return (
                                          <MenuItem key={idx} value={el?._id}>
                                            <Typography
                                              sx={{
                                                cursor: "pointer",
                                                color: "black",
                                                fontSize: "12px",
                                              }}
                                            >
                                              {el?.ProductSize}
                                            </Typography>
                                          </MenuItem>
                                        );
                                      }
                                    )
                                  : VariationToMap?.map((el, idx) => {
                                      return (
                                        <MenuItem key={idx} value={el?._id}>
                                          <Typography
                                            sx={{
                                              cursor: "pointer",
                                              color: "black",
                                              fontSize: "14px",
                                            }}
                                          >
                                            {el?.ProductSize}
                                          </Typography>
                                        </MenuItem>
                                      );
                                    })}
                              </Select>
                            </TableCell>
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  minValue = res.MinOrderQuantity;
                                  return (
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        px: 2,
                                        py: 0,
                                        width: "120px",
                                      }}
                                      align="center"
                                    >
                                      <Typography sx={tableData}>
                                        {res.MinOrderQuantity <= 0
                                          ? 0
                                          : res.MinOrderQuantity}
                                      </Typography>
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  maxValue = res?.MaxOrderQuantity;
                                  return (
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        px: 2,
                                        py: 0,
                                        width: "120px",
                                      }}
                                    >
                                      <Typography sx={tableData}>
                                        {res.MaxOrderQuantity}
                                      </Typography>
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  return (
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        px: 2,
                                        py: 0,
                                        width: "120px",
                                      }}
                                    >
                                      <Typography sx={tableData}>
                                        {" "}
                                        {
                                          GetProductByIdData
                                            .ProductsVariantions[0].GST
                                        }
                                        &nbsp;%
                                      </Typography>
                                    </TableCell>
                                  );
                                }
                              }
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "45px",
                      mt: "20px",
                    }}
                  >
                    <Box>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "30px",
                          color: "grey",
                        }}
                      >
                        Add Quantity
                      </InputLabel>

                      {!IsSample ? (
                        <>
                          <Input
                            disableUnderline
                            onWheel={numberInputOnWheelPreventChange}
                            sx={{
                              ...tableData,
                              mt: 1,
                              width: "140px",
                              height: "30px",
                              background: "#fff",
                              boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                              borderRadius: "12px 12px 12px 12px",
                              textAlign: "center",
                              px: 2,
                            }}
                            value={CustomQuantity}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (
                                inputValue.includes(".") ||
                                e.nativeEvent.inputType === "insertFromPaste"
                              ) {
                                e.preventDefault();
                                return;
                              }

                              setCustomQty(inputValue);
                            }}
                            type="number"
                            inputProps={{
                              min: minValue,
                              max: maxValue,
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Input
                            disableUnderline
                            onWheel={numberInputOnWheelPreventChange}
                            sx={{
                              ...tableData,

                              mt: 1,
                              width: "140px",
                              height: "30px",
                              background: "#fff",
                              boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                              borderRadius: "12px 12px 12px 12px",
                              textAlign: "center",
                              px: 2,
                            }}
                            disabled
                            value={CustomQuantity}
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (
                                inputValue.includes(".") ||
                                e.nativeEvent.inputType === "insertFromPaste"
                              ) {
                                e.preventDefault();
                                return;
                              }

                              setCustomQty(inputValue);
                            }}
                            type="number"
                            inputProps={{
                              min: minValue,
                              max: maxValue,
                            }}
                          />
                        </>
                      )}
                    </Box>
                    <Box>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "30px",
                          color: "grey",
                        }}
                      >
                        Total Price
                      </InputLabel>
                      <Box
                        sx={{
                          mt: 1,
                          width: "100px",
                          height: "30px",
                          background: "#FFF",
                          boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                          borderRadius: "12px 12px 12px 12px",
                          textAlign: "center",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",

                          px: 2,
                        }}
                      >
                        {!IsSample ? (
                          <>
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  const gstPrice =
                                    res?.DiscountedPrice / (1 + res?.GST / 100);
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100px",
                                        alignContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{ ...tableData, color: "#000000" }}
                                      >
                                        <CommaSeparator
                                          Price={gstPrice * CustomQuantity}
                                        />
                                      </Typography>
                                      <img
                                        src={BXITokenIcon}
                                        style={{
                                          widows: "20px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                      />
                                    </Box>
                                  );
                                }
                              }
                            )}{" "}
                          </>
                        ) : (
                          <>
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  const gstPrice =
                                    res?.PriceOfSample /
                                    (1 + res?.GST / 100) /
                                    res?.SampleAvailability;
                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100px",
                                        alignContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{ ...tableData, color: "#000000" }}
                                      >
                                        <CommaSeparator
                                          Price={gstPrice * CustomQuantity}
                                        />
                                      </Typography>
                                      <img
                                        src={BXITokenIcon}
                                        style={{
                                          widows: "20px",
                                          height: "15px",
                                          width: "15px",
                                        }}
                                      />
                                    </Box>
                                  );
                                }
                              }
                            )}
                          </>
                        )}
                      </Box>
                    </Box>

                    <Box>
                      <InputLabel
                        sx={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "30px",
                          color: "grey",
                        }}
                      >
                        Total GST
                      </InputLabel>
                      <Box
                        sx={{
                          mt: 1,
                          width: "100px",
                          height: "30px",
                          background: "#FFF",
                          boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                          borderRadius: "12px 12px 12px 12px",
                          textAlign: "center",
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          px: 2,
                        }}
                      >
                        {!IsSample ? (
                          <>
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  const gstPrice =
                                    res?.DiscountedPrice / (1 + res?.GST / 100);

                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100px",
                                        alignContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{ ...tableData, color: "#000000" }}
                                      >
                                        <CommaSeparator
                                          Price={
                                            (gstPrice *
                                              CustomQuantity *
                                              Number(res?.GST)) /
                                            100
                                          }
                                        />
                                        &nbsp; ₹
                                      </Typography>
                                    </Box>
                                  );
                                }
                              }
                            )}
                          </>
                        ) : (
                          <>
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  const gstPrice =
                                    res?.PriceOfSample /
                                    (1 + res?.GST / 100) /
                                    res?.SampleAvailability;

                                  return (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        width: "100px",
                                        alignContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Typography
                                        sx={{ ...tableData, color: "#000000" }}
                                      >
                                        <CommaSeparator
                                          Price={
                                            (gstPrice *
                                              CustomQuantity *
                                              Number(res?.GST)) /
                                            100
                                          }
                                        />
                                        &nbsp; ₹
                                      </Typography>
                                    </Box>
                                  );
                                }
                              }
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      {!StoreProductSize ? (
                        <>
                          {cartItems?.find(
                            (item) => item?.ProductId?._id === ProductId
                          ) ? (
                            <Button
                              sx={{
                                ...uploadBtn,
                                width: "auto",
                                minWidth: "140px",
                                height: "30px",
                                fontSize: "16px",
                                borderRadius: "12px",
                                boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                              }}
                              onClick={duplicateCartItem}
                            >
                              Go To Cart
                            </Button>
                          ) : (
                            addToCardView === false && (
                              <Button
                                sx={{
                                  ...uploadBtn,
                                  width: "auto",
                                  minWidth: "140px",
                                  height: "30px",
                                  fontSize: "16px",
                                  borderRadius: "12px",
                                  boxShadow:
                                    "0px 4px 8px rgba(220, 220, 220, 1)",
                                }}
                                onClick={handleAddToCart}
                              >
                                Add to cart
                              </Button>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          {GetProductByIdData?.ProductsVariantions?.map(
                            (res, idx) => {
                              if (res?._id === storeVariationData) {
                                minValue = res.MinOrderQuantity;

                                const error =
                                  res.MaxOrderQuantity < res.MinOrderQuantity ||
                                  res.MaxOrderQuantity === 0;

                                return error ? (
                                  <Button
                                    sx={{
                                      ...uploadBtn,
                                      width: "auto",
                                      minWidth: "140px",
                                      height: "30px",
                                      fontSize: "16px",
                                      borderRadius: "12px",
                                      boxShadow:
                                        "0px 4px 8px rgba(220, 220, 220, 1)",
                                      background: "red",
                                      "&:hover": {
                                        background: "red",
                                      },
                                    }}
                                  >
                                    Out Of Stock
                                  </Button>
                                ) : (
                                  <>
                                    {cartItems?.find(
                                      (item) =>
                                        item?.ProductId?._id === ProductId
                                    ) ? (
                                      <Button
                                        sx={{
                                          ...uploadBtn,
                                          width: "auto",
                                          minWidth: "140px",
                                          height: "30px",
                                          fontSize: "16px",
                                          borderRadius: "12px",
                                          boxShadow:
                                            "0px 4px 8px rgba(220, 220, 220, 1)",
                                        }}
                                        onClick={duplicateCartItem}
                                      >
                                        Go To Cart
                                      </Button>
                                    ) : (
                                      addToCardView === false && (
                                        <Button
                                          sx={{
                                            ...uploadBtn,
                                            width: "auto",
                                            minWidth: "140px",
                                            height: "30px",
                                            fontSize: "16px",
                                            borderRadius: "12px",
                                            boxShadow:
                                              "0px 4px 8px rgba(220, 220, 220, 1)",
                                          }}
                                          onClick={handleAddToCart}
                                        >
                                          Add to cart
                                        </Button>
                                      )
                                    )}
                                  </>
                                );
                              }
                            }
                          )}
                        </>
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignContent: "center",
                      alignItems: "center",
                      borderTop: "2px solid #EEEEEE",
                      mt: "15px",
                      pt: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ ...chart, cursor: "pointer" }}
                        onMouseEnter={() => setShowSizechart(true)}
                        onMouseLeave={() => setShowSizechart(false)}
                        onClick={handleDownloadSizechart}
                      >
                        Size Chart{" "}
                      </Typography>
                      {GetProductByIdData && (
                        <>
                          {GetProductByIdData?.SizeChart?.at(0)?.url ===
                            undefined ||
                          GetProductByIdData?.SizeChart?.at(0)?.url === null ? (
                            showSizechart ? (
                              <Typography
                                sx={{
                                  ...chart,
                                  position: "absolute",
                                  height: "300px",
                                  width: "auto",
                                  right: "5%",
                                  zIndex: 10,
                                }}
                              >
                                Size Chart Unavailable
                              </Typography>
                            ) : null
                          ) : showSizechart ? (
                            <Box
                              component="img"
                              src={GetProductByIdData?.SizeChart?.at(0)?.url}
                              alt="hello"
                              onMouseEnter={() => setShowSizechart(true)}
                              onMouseLeave={() => setShowSizechart(false)}
                              sx={{
                                position: "absolute",
                                height: "300px",
                                width: "auto",
                                right: "5%",
                                zIndex: 10,
                              }}
                            />
                          ) : null}
                        </>
                      )}
                    </Box>
                    <Box>
                      {GetProductByIdData?.ProductsVariantions[0]
                        ?.PriceOfSample ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                            {!IsSample ? (
                              <Box
                                component="img"
                                src={UnSelectedImg}
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setIsSample(true);
                                }}
                              ></Box>
                            ) : (
                              <Box
                                component="img"
                                src={SelectedImg}
                                sx={{
                                  width: "18px",
                                  height: "18px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setIsSample(false);
                                }}
                              ></Box>
                            )}
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                lineHeight: "24px",
                                color: "#6B7A99",
                              }}
                            >
                              Do you want to first try a product sample ?
                            </Typography>
                          </Box>
                        </>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Grid container>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12} sx={{ mt: 6 }}>
                  <Box
                    style={{
                      width: "632px",
                      maxHeight: "432px",
                      cursor: "pointer",
                    }}
                  >
                    <CarouselForProductHorizontal
                      ImageDataArray={ImageDataArray}
                    />
                  </Box>
                </Grid>
                <Grid item xl={1.5} lg={1.5} md={1.5}></Grid>
                <Grid
                  item
                  xl={6.5}
                  lg={6.5}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ ...fixGrid, mt: 0 }}
                >
                  <Box sx={{ marginTop: "0px" }}>
                    <Typography sx={{ ...semi, fontSize: "20px" }}>
                      {GetProductByIdData?.ProductName}
                    </Typography>
                    <Typography
                      sx={{
                        ...semi,
                        color: "#6B7A99",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      {GetProductByIdData?.SellerCompanyName}
                    </Typography>
                    <Box sx={{ mt: 3 }}>
                      <DiscountedPrice
                        regularPrice={priceone}
                        discountPrice={priceTwo}
                        percentage={
                          GetProductByIdData &&
                          GetProductByIdData?.ProductsVariantions?.length > 0 &&
                          GetProductByIdData?.ProductsVariantions[0]?.GST
                        }
                      />
                    </Box>

                    <Box sx={{ display: "flex", gap: "70px", mt: 1 }}>
                      {GetProductByIdData?.ProductCategoryName === "QSR" ||
                      GetProductByIdData?.ProductCategoryName ===
                        "FMCG" ? null : (
                        <>
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "grey",
                                mt: 2,
                              }}
                            >
                              Available Colors
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: {
                                  x: "flex-start",
                                  lg: "flex-start",
                                  md: "flex-start",
                                  sm: "flex-start",
                                  xs: "center",
                                },
                                width: "100%",
                                overflow: "auto",
                                "::-webkit-scrollbar": {
                                  mt: 1,
                                  display: "flex",
                                  height: "4px",
                                },
                              }}
                            >
                              {GetProductByIdData &&
                                NewdataArray?.map((res, idx) => {
                                  return (
                                    <Box
                                      key={idx}
                                      onClick={() => {
                                        setVariationToMap(
                                          res?.ProductVariations
                                        );
                                        setBorderColor(res?.ProductColor);
                                      }}
                                      sx={{
                                        background: res?.ProductColor,
                                        width: {
                                          xl: "25%",
                                          lg: "25%",
                                          md: "25%",
                                          sm: "5%",
                                          xs: "13%",
                                        },
                                        mb: 1,
                                        ml: 0,
                                        mt: 1,
                                        height: "100%",
                                        minHeight: "30px",
                                        maxWidth: "25%",
                                        minWidth: "25%",
                                        borderRadius: "0.5rem",
                                        cursor: "pointer",
                                        transition:
                                          res?.ProductColor === borderColor
                                            ? "0.2s linear"
                                            : null,
                                        boxShadow:
                                          res?.ProductColor === borderColor
                                            ? "5px 5px 5px grey"
                                            : null,

                                        border:
                                          res?.ProductColor === borderColor
                                            ? "2px solid #000"
                                            : "2px solid lightgray",
                                      }}
                                    ></Box>
                                  );
                                })}
                            </Box>
                          </Box>
                        </>
                      )}
                      <Box>
                        {GetProductByIdData?.gender === null ||
                        GetProductByIdData?.gender === undefined ? null : (
                          <Box>
                            <Typography
                              sx={{
                                ...semiSub,
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "24px",
                                color: "grey",
                              }}
                            >
                              Gender
                            </Typography>
                            <Typography
                              sx={{
                                ...tableData,
                                textAlign: "start",
                                lineHeight: "3rem",
                                fontSize: "14px",
                                color: "grey",
                                fontWeight: 600,
                              }}
                            >
                              {GetProductByIdData?.gender}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>

                    <Box
                      mt={1.5}
                      sx={{
                        width: "100%",
                        mx: "auto",
                        overflow: "hidden",
                      }}
                    >
                      <TableContainer sx={{ overflow: "hidden" }}>
                        <Table sx={{ overflow: "hidden" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ borderBottom: "none" }}>
                                <Typography
                                  sx={{
                                    marginLeft: "-15px",
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "grey",
                                  }}
                                >
                                  {getSizeCategory(
                                    GetProductByIdData?.ProductsVariantions[0]
                                      ?.ProductSize
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  width: "100px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "grey",
                                  }}
                                >
                                  Min QTY
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{ borderBottom: "none", width: "120px" }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "grey",
                                  }}
                                >
                                  Max QTY
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{ borderBottom: "none", width: "150px" }}
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "grey",
                                  }}
                                >
                                  GST{" "}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow sx={{}}>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  width: "60px",
                                  height: "30px",
                                  p: 0,
                                }}
                              >
                                <Select
                                  sx={{
                                    width: "140px",
                                    marginLeft: "0px",
                                    marginRight: "auto",
                                    height: "30px",
                                    marginTop: "-10px",
                                    border: "1px solid #8C8C8C",
                                    borderRadius: "10px",
                                    cursor: "pointer",
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "transparent",
                                      },
                                  }}
                                  defaultValue={"hello"}
                                  onChange={(e) => {
                                    setStoreVariationData(e.target.value);
                                    setIsSample(false);
                                    setProductSize(e.target.value);
                                  }}
                                >
                                  <MenuItem disabled>
                                    <Typography
                                      sx={{
                                        cursor: "pointer",
                                        color: "black",
                                        fontSize: "10px",
                                      }}
                                    >
                                      Select a Size
                                    </Typography>
                                  </MenuItem>
                                  {!VariationToMap && NewdataArray?.length > 0
                                    ? !VariationToMap &&
                                      NewdataArray[0]?.ProductVariations?.map(
                                        (el, idx) => {
                                          return (
                                            <MenuItem key={idx} value={el?._id}>
                                              <Typography
                                                sx={{
                                                  cursor: "pointer",
                                                  color: "black",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                {el?.ProductSize
                                                  ? el?.ProductSize
                                                  : el?.DiscountedPrice}
                                              </Typography>
                                            </MenuItem>
                                          );
                                        }
                                      )
                                    : VariationToMap?.map((el, idx) => {
                                        return (
                                          <MenuItem key={idx} value={el?._id}>
                                            <Typography
                                              sx={{
                                                cursor: "pointer",
                                                color: "black",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {el?.ProductSize
                                                ? el?.ProductSize
                                                : el?.DiscountedPrice}
                                            </Typography>
                                          </MenuItem>
                                        );
                                      })}
                                </Select>
                              </TableCell>
                              {GetProductByIdData?.ProductsVariantions?.map(
                                (res, idx) => {
                                  if (res?._id === storeVariationData) {
                                    minValue = res.MinOrderQuantity;
                                    return (
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          px: 2,
                                          py: 0,
                                          width: "120px",
                                        }}
                                        align="center"
                                      >
                                        <Typography sx={tableData}>
                                          {res.MinOrderQuantity <= 0
                                            ? 0
                                            : res.MinOrderQuantity}
                                        </Typography>
                                      </TableCell>
                                    );
                                  }
                                }
                              )}
                              {GetProductByIdData?.ProductsVariantions?.map(
                                (res, idx) => {
                                  if (res?._id === storeVariationData) {
                                    maxValue = res?.MaxOrderQuantity;
                                    return (
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          px: 2,
                                          py: 0,
                                          width: "120px",
                                        }}
                                      >
                                        <Typography sx={tableData}>
                                          {res.MaxOrderQuantity <= 0
                                            ? 0
                                            : res.MaxOrderQuantity}
                                        </Typography>
                                      </TableCell>
                                    );
                                  }
                                }
                              )}
                              {GetProductByIdData?.ProductsVariantions?.map(
                                (res, idx) => {
                                  if (res?._id === storeVariationData) {
                                    return (
                                      <TableCell
                                        sx={{
                                          borderBottom: "none",
                                          px: 2,
                                          py: 0,
                                          width: "120px",
                                        }}
                                      >
                                        <Typography sx={tableData}>
                                          {" "}
                                          {
                                            GetProductByIdData
                                              .ProductsVariantions[0].GST
                                          }
                                          &nbsp;%
                                        </Typography>
                                      </TableCell>
                                    );
                                  }
                                }
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "45px",
                        mt: "20px",
                      }}
                    >
                      <Box>
                        <InputLabel
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "30px",
                            color: "grey",
                          }}
                        >
                          Add Quantity
                        </InputLabel>
                        <Input
                          disableUnderline
                          onWheel={numberInputOnWheelPreventChange}
                          sx={{
                            ...tableData,

                            mt: 1,
                            width: "140px",
                            height: "30px",
                            background: "#fff",
                            boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                            borderRadius: "12px 12px 12px 12px",
                            textAlign: "center",
                            px: 2,
                          }}
                          value={CustomQuantity}
                          onChange={(e) => {
                            const inputValue = e.target.value;

                            if (
                              inputValue.includes(".") ||
                              e.nativeEvent.inputType === "insertFromPaste"
                            ) {
                              e.preventDefault();
                              return;
                            }

                            setCustomQty(inputValue);
                          }}
                          type="number"
                          inputProps={{
                            min: minValue,
                            max: maxValue,
                          }}
                        />
                      </Box>
                      <Box>
                        <InputLabel
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "30px",
                            color: "grey",
                          }}
                        >
                          Total Price
                        </InputLabel>
                        <Box
                          sx={{
                            mt: 1,
                            width: "100px",
                            height: "30px",
                            background: "#FFF",
                            boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                            borderRadius: "12px 12px 12px 12px",
                            textAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",

                            px: 2,
                          }}
                        >
                          {GetProductByIdData?.ProductsVariantions?.map(
                            (res, idx) => {
                              if (res?._id === storeVariationData) {
                                const gstPrice =
                                  res?.DiscountedPrice / (1 + res?.GST / 100);
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      width: "100px",
                                      alignContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{ ...tableData, color: "#000000" }}
                                    >
                                      <CommaSeparator
                                        Price={gstPrice * CustomQuantity}
                                      />
                                    </Typography>
                                    <img
                                      src={BXITokenIcon}
                                      style={{
                                        widows: "20px",
                                        height: "15px",
                                        width: "15px",
                                      }}
                                    />
                                  </Box>
                                );
                              }
                            }
                          )}
                        </Box>
                      </Box>

                      <Box>
                        <InputLabel
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "30px",
                            color: "grey",
                          }}
                        >
                          Total GST
                        </InputLabel>
                        <Box
                          sx={{
                            mt: 1,
                            width: "100px",
                            height: "30px",
                            background: "#FFF",
                            boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                            borderRadius: "12px 12px 12px 12px",
                            textAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                            px: 2,
                          }}
                        >
                          {GetProductByIdData?.ProductsVariantions?.map(
                            (res, idx) => {
                              if (res?._id === storeVariationData) {
                                const gstPrice =
                                  res?.DiscountedPrice / (1 + res?.GST / 100);

                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      width: "100px",
                                      alignContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{ ...tableData, color: "#000000" }}
                                    >
                                      <CommaSeparator
                                        Price={
                                          (gstPrice *
                                            CustomQuantity *
                                            Number(res?.GST)) /
                                          100
                                        }
                                      />
                                      &nbsp; ₹
                                    </Typography>
                                  </Box>
                                );
                              }
                            }
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "flex-end",
                        }}
                      >
                        {!StoreProductSize ? (
                          <>
                            {cartItems?.find(
                              (item) => item?.ProductId?._id === ProductId
                            ) ? (
                              <Button
                                sx={{
                                  ...uploadBtn,
                                  width: "auto",
                                  minWidth: "140px",
                                  height: "30px",
                                  fontSize: "16px",
                                  borderRadius: "12px",
                                  boxShadow:
                                    "0px 4px 8px rgba(220, 220, 220, 1)",
                                }}
                                onClick={duplicateCartItem}
                              >
                                Go To Cart
                              </Button>
                            ) : (
                              addToCardView === false && (
                                <Button
                                  sx={{
                                    ...uploadBtn,
                                    width: "auto",
                                    minWidth: "140px",
                                    height: "30px",
                                    fontSize: "16px",
                                    borderRadius: "12px",
                                    boxShadow:
                                      "0px 4px 8px rgba(220, 220, 220, 1)",
                                  }}
                                  onClick={handleAddToCart}
                                >
                                  Add to cart
                                </Button>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {GetProductByIdData?.ProductsVariantions?.map(
                              (res, idx) => {
                                if (res?._id === storeVariationData) {
                                  minValue = res.MinOrderQuantity;

                                  const error =
                                    res.MaxOrderQuantity <
                                      res.MinOrderQuantity ||
                                    res.MaxOrderQuantity === 0;

                                  return error ? (
                                    <Button
                                      sx={{
                                        ...uploadBtn,
                                        width: "auto",
                                        minWidth: "140px",
                                        height: "30px",
                                        fontSize: "16px",
                                        borderRadius: "12px",
                                        boxShadow:
                                          "0px 4px 8px rgba(220, 220, 220, 1)",
                                        background: "red",
                                        "&:hover": {
                                          background: "red",
                                        },
                                      }}
                                    >
                                      Out Of Stock
                                    </Button>
                                  ) : (
                                    <>
                                      {cartItems?.find(
                                        (item) =>
                                          item?.ProductId?._id === ProductId
                                      ) ? (
                                        <Button
                                          sx={{
                                            ...uploadBtn,
                                            width: "auto",
                                            minWidth: "140px",
                                            height: "30px",
                                            fontSize: "16px",
                                            borderRadius: "12px",
                                            boxShadow:
                                              "0px 4px 8px rgba(220, 220, 220, 1)",
                                          }}
                                          onClick={duplicateCartItem}
                                        >
                                          Go To Cart
                                        </Button>
                                      ) : (
                                        addToCardView === false && (
                                          <Button
                                            sx={{
                                              ...uploadBtn,
                                              width: "auto",
                                              minWidth: "140px",
                                              height: "30px",
                                              fontSize: "16px",
                                              borderRadius: "12px",
                                              boxShadow:
                                                "0px 4px 8px rgba(220, 220, 220, 1)",
                                            }}
                                            onClick={handleAddToCart}
                                          >
                                            Add to cart
                                          </Button>
                                        )
                                      )}
                                    </>
                                  );
                                }
                              }
                            )}
                          </>
                        )}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        borderTop: "2px solid #EEEEEE",
                        mt: "15px",
                        pt: "10px",
                        width: "95%",
                        ml: "0%",
                        mr: "auto",
                      }}
                    >
                      {!GetProductByIdData ? null : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                          }}
                        >
                          <Typography
                            sx={{ ...chart, cursor: "pointer" }}
                            onMouseEnter={() => setShowSizechart(true)}
                            onMouseLeave={() => setShowSizechart(false)}
                            onClick={handleDownloadSizechart}
                          >
                            Size Chart
                          </Typography>

                          {GetProductByIdData?.SizeChart?.at(0)?.url ===
                            undefined ||
                          GetProductByIdData?.SizeChart?.at(0)?.url === null ? (
                            showSizechart ? (
                              <Typography
                                sx={{
                                  ...chart,
                                  position: "absolute",
                                  height: "300px",
                                  width: "auto",
                                  right: "5%",
                                  zIndex: 10,
                                }}
                              >
                                Size Chart Unavailable
                              </Typography>
                            ) : null
                          ) : showSizechart ? (
                            <Box
                              component="img"
                              src={GetProductByIdData?.SizeChart?.at(0)?.url}
                              alt="hello"
                              onMouseEnter={() => setShowSizechart(true)}
                              onMouseLeave={() => setShowSizechart(false)}
                              sx={{
                                position: "absolute",
                                height: "300px",
                                width: "auto",
                                right: "5%",
                                zIndex: 10,
                              }}
                            />
                          ) : null}
                        </Box>
                      )}

                      <Box>
                        {GetProductByIdData?.ProductsVariantions[0]
                          ?.PriceOfSample ||
                        GetProductByIdData?.ProductsVariantions[1]
                          ?.PriceOfSample ? (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              {!IsSample ? (
                                <Box
                                  component="img"
                                  src={UnSelectedImg}
                                  sx={{
                                    width: "18px",
                                    height: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setIsSample(true)}
                                ></Box>
                              ) : (
                                <Box
                                  component="img"
                                  src={SelectedImg}
                                  sx={{
                                    width: "18px",
                                    height: "18px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => setIsSample(false)}
                                ></Box>
                              )}
                              <Typography
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  lineHeight: "24px",
                                  color: "#6B7A99",
                                }}
                              >
                                Do you want to first try a product sample ?
                              </Typography>
                            </Box>
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}

          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ mt: 6 }}>
              <Box
                sx={{
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "100%",
                    xs: "100%",
                  },
                  typography: "body1",
                }}
              >
                <TabContext value={value}>
                  <Box>
                    <TabPanel value="1">
                      {CompanyTypeData?.data?.CompanyTypeName === "Textile" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Lifestyle" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "Office Supply" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Others" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "QSR" ? null : (
                        <>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              mt: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                gap: 0.5,
                              }}
                            >
                              <Typography
                                sx={{
                                  ...tabText,
                                  fontSize: "16px",
                                  fontWeight: 600,
                                }}
                              >
                                {GetProductByIdData &&
                                  GetProductByIdData.ProductSubtittle}
                              </Typography>
                              <Typography sx={tabSubText}>
                                {GetProductByIdData &&
                                  GetProductByIdData.ProductDescription}
                              </Typography>
                            </Box>
                          </Grid>
                          <Box sx={{ mt: 2 }}>
                            <Typography
                              sx={{
                                color: "#156DB6",
                                fontSize: "22px",
                                fontFamily: "Poppins",
                                fontWeight: 600,
                              }}
                            >
                              Price & Availability
                            </Typography>
                          </Box>
                        </>
                      )}

                      {CompanyTypeData?.data?.CompanyTypeName === "Textile" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Lifestyle" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "Office Supply" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Others" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "Electronics" ||
                      CompanyTypeData?.data?.CompanyTypeName === "FMCG" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Hotel" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Mobility" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "QSR" ? null : (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "40px",
                            }}
                          ></Box>

                          <Box
                            mt={2}
                            sx={{
                              width: "60%",
                              // mx: "auto",
                              overflow: "hidden",
                            }}
                          >
                            <TableContainer sx={{ overflow: "hidden" }}>
                              <Table sx={{ overflow: "hidden" }}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ borderBottom: "none" }}>
                                      <Typography
                                        sx={{
                                          ...tableHeader,
                                          ml: "-15px",
                                          fontSize: "15px",
                                          lineHeight: "24px",
                                          color: "#000",
                                          fontWeight: 500,
                                        }}
                                      >
                                        {getSizeCategory(
                                          GetProductByIdData
                                            ?.ProductsVariantions[0]
                                            ?.ProductSize
                                        )}
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        width: "120px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...tableHeader,
                                          fontSize: "15px",
                                          lineHeight: "24px",
                                          color: "#000",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Min QTY
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        width: "120px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...tableHeader,
                                          fontSize: "15px",
                                          lineHeight: "24px",
                                          color: "#000",
                                          fontWeight: 500,
                                        }}
                                      >
                                        Max QTY
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        width: "120px",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          ...tableHeader,
                                          fontSize: "15px",
                                          lineHeight: "24px",
                                          color: "#000",
                                          fontWeight: 500,
                                        }}
                                      >
                                        GST{" "}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow sx={{ p: 0 }}>
                                    <TableCell
                                      sx={{
                                        borderBottom: "none",
                                        width: "200px",
                                        height: "30px",
                                        p: 0,
                                      }}
                                    >
                                      <Select
                                        label="select"
                                        sx={{
                                          width: "140px",
                                          marginLeft: "0px",
                                          marginRight: "auto",
                                          height: "35px",
                                          marginTop: "0px",
                                          border: "1px solid #8C8C8C",
                                          borderRadius: "10px",
                                          cursor: "pointer",
                                          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "transparent",
                                            },
                                          "&:focus": {
                                            outline: "none",
                                          },
                                          "& fieldset": { border: "none" },
                                        }}
                                        defaultValue={"hello"}
                                        onChange={(e) => {
                                          setStoreVariationData(e.target.value);
                                          setIsSample(false);
                                          setProductSize(e.target.value);
                                        }}
                                      >
                                        <MenuItem disabled>
                                          <Typography
                                            sx={{
                                              // cursor: "pointer",
                                              fontSize: "15px",
                                              lineHeight: "24px",
                                              color: "#000",
                                              fontWeight: 500,
                                              fontFamily: "Poppins",
                                            }}
                                          >
                                            Select a Size
                                          </Typography>
                                        </MenuItem>
                                        {!VariationToMap &&
                                        NewdataArray?.length > 0
                                          ? !VariationToMap &&
                                            NewdataArray[0]?.ProductVariations?.map(
                                              (el, idx) => {
                                                return (
                                                  <MenuItem
                                                    key={idx}
                                                    value={el?._id}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        cursor: "pointer",
                                                        color: "black",
                                                        fontSize: "12px",
                                                      }}
                                                    >
                                                      {el?.ProductSize}
                                                    </Typography>
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                          : VariationToMap?.map((el, idx) => {
                                              return (
                                                <MenuItem
                                                  key={idx}
                                                  value={el?._id}
                                                >
                                                  <Typography
                                                    sx={{
                                                      cursor: "pointer",
                                                      color: "black",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {el?.ProductSize}
                                                  </Typography>
                                                </MenuItem>
                                              );
                                            })}
                                      </Select>
                                    </TableCell>
                                    {GetProductByIdData?.ProductsVariantions?.map(
                                      (res, idx) => {
                                        if (res?._id === storeVariationData) {
                                          minValue = res.MinOrderQuantity;
                                          return (
                                            <TableCell
                                              sx={{
                                                borderBottom: "none",
                                                px: 0,
                                                py: 0,
                                                width: "120px",
                                              }}
                                              align="center"
                                            >
                                              <Typography sx={tableData}>
                                                {res.MinOrderQuantity <= 0
                                                  ? 0
                                                  : res.MinOrderQuantity}
                                              </Typography>
                                            </TableCell>
                                          );
                                        }
                                      }
                                    )}
                                    {GetProductByIdData?.ProductsVariantions?.map(
                                      (res, idx) => {
                                        if (res?._id === storeVariationData) {
                                          maxValue = res?.MaxOrderQuantity;
                                          return (
                                            <TableCell
                                              sx={{
                                                borderBottom: "none",
                                                px: 2,
                                                py: 0,
                                                width: "120px",
                                              }}
                                            >
                                              <Typography sx={tableData}>
                                                {res.MaxOrderQuantity}
                                              </Typography>
                                            </TableCell>
                                          );
                                        }
                                      }
                                    )}
                                    {GetProductByIdData?.ProductsVariantions?.map(
                                      (res, idx) => {
                                        if (res?._id === storeVariationData) {
                                          return (
                                            <TableCell
                                              sx={{
                                                borderBottom: "none",
                                                px: 2,
                                                py: 0,
                                                width: "120px",
                                              }}
                                            >
                                              <Typography sx={tableData}>
                                                {" "}
                                                {
                                                  GetProductByIdData
                                                    .ProductsVariantions[0].GST
                                                }
                                                &nbsp;%
                                              </Typography>
                                            </TableCell>
                                          );
                                        }
                                      }
                                    )}
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              gap: "120px",
                              // mt: "40px",
                            }}
                          >
                            {GetProductByIdData?.ProductCategoryName ===
                              "QSR" ||
                            GetProductByIdData?.ProductCategoryName ===
                              "FMCG" ? null : (
                              <>
                                <Box>
                                  <Typography
                                    sx={{
                                      ...semiSub,
                                      color: "#000",
                                      fontWeight: 500,
                                    }}
                                  >
                                    Available colors
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: {
                                        x: "flex-start",
                                        lg: "flex-start",
                                        md: "flex-start",
                                        sm: "flex-start",
                                        xs: "center",
                                      },
                                      width: "110%",
                                      overflow: "auto",
                                      "::-webkit-scrollbar": {
                                        mt: 1,
                                        display: "flex",
                                        height: "4px",
                                      },
                                    }}
                                  >
                                    {GetProductByIdData &&
                                      NewdataArray?.map((res, idx) => {
                                        return (
                                          <Box
                                            key={idx}
                                            onClick={() => {
                                              setVariationToMap(
                                                res?.ProductVariations
                                              );
                                              setBorderColor(res?.ProductColor);
                                            }}
                                            sx={{
                                              background: res?.ProductColor,
                                              width: {
                                                xl: "25%",
                                                lg: "25%",
                                                md: "25%",
                                                sm: "5%",
                                                xs: "13%",
                                              },
                                              mb: 1,
                                              ml: 1,
                                              height: "100%",
                                              minHeight: "35px",
                                              maxWidth: "25%",
                                              minWidth: "25%",
                                              borderRadius: "0.5rem",
                                              cursor: "pointer",
                                              transition:
                                                res?.ProductColor ===
                                                borderColor
                                                  ? "0.2s linear"
                                                  : null,
                                              boxShadow:
                                                res?.ProductColor ===
                                                borderColor
                                                  ? "5px 5px 5px grey"
                                                  : null,

                                              border:
                                                res?.ProductColor ===
                                                borderColor
                                                  ? "2px solid #000"
                                                  : "2px solid lightgray",
                                            }}
                                          ></Box>
                                        );
                                      })}
                                  </Box>
                                </Box>
                              </>
                            )}
                            <Box sx={{ mt: 2.7, ml: "0px" }}>
                              <InputLabel
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  lineHeight: "24px",
                                  color: "#000",
                                }}
                              >
                                Add Quantity
                              </InputLabel>

                              <Input
                                disableUnderline
                                onWheel={numberInputOnWheelPreventChange}
                                sx={{
                                  ...tableData,
                                  mt: 1,
                                  width: "126px",
                                  height: "30px",
                                  background: "#fff",
                                  boxShadow: "1.5px 2px 2px 1.5px #B2BCCF",
                                  borderRadius: "12px 12px 12px 12px",
                                  borderRadius: "8px",
                                  textAlign: "center",
                                  px: 2,
                                }}
                                value={CustomQuantity}
                                onChange={(e) => {
                                  const inputValue = e.target.value;

                                  if (
                                    inputValue.includes(".") ||
                                    e.nativeEvent.inputType ===
                                      "insertFromPaste"
                                  ) {
                                    e.preventDefault();
                                    return;
                                  }

                                  setCustomQty(inputValue);
                                }}
                                type="number"
                                inputProps={{
                                  min: minValue,
                                  max: maxValue,
                                }}
                              />
                            </Box>
                            <Box sx={{ mt: 2.7, ml: "-65px" }}>
                              <InputLabel
                                sx={{
                                  fontFamily: "Poppins",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  lineHeight: "24px",
                                  color: "#000",
                                }}
                              >
                                Total Price
                              </InputLabel>
                              <Box
                                sx={{
                                  boxShadow: "1.5px 2px 2px 1.5px #B2BCCF",
                                  borderRadius: "12px 12px 12px 12px",
                                  mt: 1,
                                  width: "85px",
                                  height: "32px",
                                  background: "#fff",
                                  // borderRadius: "6px",
                                  textAlign: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",

                                  px: 2,
                                }}
                              >
                                {GetProductByIdData?.ProductsVariantions?.map(
                                  (res, idx) => {
                                    if (res?._id === storeVariationData) {
                                      const gstPrice =
                                        res?.DiscountedPrice /
                                        (1 + res?.GST / 100);
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            width: "100px",
                                            alignContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              ...tableData,
                                              color: "#000000",
                                            }}
                                          >
                                            <CommaSeparator
                                              Price={gstPrice * CustomQuantity}
                                            />
                                          </Typography>
                                          <img
                                            src={BXITokenIcon}
                                            style={{
                                              widows: "20px",
                                              height: "15px",
                                              width: "15px",
                                            }}
                                          />
                                        </Box>
                                      );
                                    }
                                  }
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )}

                      {CompanyTypeData?.data?.CompanyTypeName === "Textile" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Lifestyle" ||
                      CompanyTypeData?.data?.CompanyTypeName ===
                        "Office Supply" ||
                      CompanyTypeData?.data?.CompanyTypeName === "Others" ||
                      CompanyTypeData?.data?.CompanyTypeName === "QSR" ? (
                        <ProductDescriptions data={GetProductByIdData} />
                      ) : null}

                      <Grid container sx={{ mt: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <SampleDetails
                            data={GetProductByIdData}
                            samplestate={samplestate}
                          />
                          <Box sx={{ display: "flex", gap: "10px" }}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Grid
                                container
                                sx={{
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  mt={3}
                                  sx={{
                                    color: "#6B7A99",
                                    display: "flex",
                                    gap: "10px",
                                    flexDirection: "column",
                                  }}
                                >
                                  <PickUplocations data={GetProductByIdData} />
                                  <AdditionalCost data={GetProductByIdData} />
                                  <ManufacturingAndExpiry
                                    data={GetProductByIdData}
                                  />
                                </Box>
                              </Grid>
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                      <Box sx={{ borderTop: "0.2px solid #156DB6", mt: 4 }}>
                        <Box sx={{ mt: 4 }}>
                          <TechnicalInfor ProductData={GetProductByIdData} />
                          <PackagingInfor
                            data={technicalinfo}
                            ProductData={GetProductByIdData}
                          />

                          <InstructionsToUseProduct
                            ProductData={GetProductByIdData}
                          />

                          <PackagingAndDelivery
                            ProductData={GetProductByIdData}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{ borderBottom: "0.2px solid #156DB6", mt: 4 }}
                      ></Box>
                      {/* Tabpanel 4 data */}

                      <KeyFeatures data={storeTechnicalInfo} />
                    </TabPanel>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
          </Grid>
          <Box
            // className={classes}
            sx={{
              width: "95%",
              mx: "auto",
            }}
          >
            {!StoreProductSize ? (
              <>
                {cartItems?.find(
                  (item) => item?.ProductId?._id === ProductId
                ) ? (
                  <Button sx={uploadBtn} onClick={duplicateCartItem}>
                    Go To Cart
                  </Button>
                ) : (
                  addToCardView === false && (
                    <Button sx={uploadBtn} onClick={handleAddToCart}>
                      Add to cart
                    </Button>
                  )
                )}
              </>
            ) : (
              <>
                {GetProductByIdData?.ProductsVariantions?.map((res, idx) => {
                  if (res?._id === storeVariationData) {
                    minValue = res.MinOrderQuantity;

                    const error =
                      res.MaxOrderQuantity < res.MinOrderQuantity ||
                      res.MaxOrderQuantity === 0;

                    return error ? (
                      <Button
                        sx={{
                          ...uploadBtn,
                          background: "red",
                          "&:hover": {
                            background: "red",
                          },
                        }}
                      >
                        Out Of Stock
                      </Button>
                    ) : (
                      <>
                        {cartItems?.find(
                          (item) => item?.ProductId?._id === ProductId
                        ) ? (
                          <Button sx={uploadBtn} onClick={duplicateCartItem}>
                            Go To Cart
                          </Button>
                        ) : (
                          addToCardView === false && (
                            <Button sx={uploadBtn} onClick={handleAddToCart}>
                              Add to cart
                            </Button>
                          )
                        )}
                      </>
                    );
                  }
                })}
              </>
            )}
          </Box>
        </Paper>
      </Paper>
    </React.Fragment>
  );
};

export default ProductDetails;

const fixGrid = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

const semi = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "2.2rem",
    lg: "2.2rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#4D4D4D",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  mt: { xl: 0, lg: 0, md: 0, sm: 0, xs: 2 },
};

const semiSub = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "40px",
  color: "#6B7A99",
  fontSize: {
    xl: "16px",
    lg: "16px",
    md: "16px",
    sm: "14px",
    xs: "12px",
  },
  mt: 2,
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  // ml: { xl: 2, lg: 2, md: 0, sm: 0, xs: 0 },
};

const tableHeader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.4rem",
    xs: "1rem",
  },
  color: "#6B7A99",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const tableData = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.6rem",
    sm: "1.6rem",
    xs: "1.5rem",
  },
  color: "#445FD2",
  lineHeight: "1rem",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const chart = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.4rem",
    sm: "1.3rem",
    xs: "1.3rem",
  },
  lineHeight: "5rem",
  color: "#445FD2",
};

const tabText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2rem",
    lg: "2rem",
    md: "1.8rem",
    sm: "1.6rem",
    xs: "1.6rem",
  },
  width: "95%",
  color: "#6B7A99",
  // textAlign: "center",
};

const tabSubText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "1.5rem",
    lg: "1.5rem",
    md: "1.5rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  width: "95%",
  textAlign: "justify",
  color: "#6B7A99",
};

const uploadBtn = {
  width: "100%",
  background: "rgba(68, 95, 210, 1)",
  "&:hover": {
    background: "rgba(68, 95, 210, 1)",
  },
  color: "#fff",
  fontFamily: "Work Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  borderRadius: "0.6rem",
  textTransform: "none",
};
