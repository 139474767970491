import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  TableContainer,
  TextField,
  Typography,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
} from "@mui/material";
import { Stack } from "@mui/system";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import axios from "axios";
import dayjs from "dayjs";
import CommaSeparator from "../../../components/CommaSeprator";
import {
  default as isBetween,
  default as isBetweenPlugin,
} from "dayjs/plugin/isBetween";
import PreviewPageHeader from "../../../components/PreviewPageHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CommaSeprator from "../../../components/CommaSeprator";
import { useAddMediaToCart } from "../../../Hooks/MediaHooks/useAddMediaToCart";
import { useGetMediaCart } from "../../../Hooks/MediaHooks/useGetMediaCart";
import LeftArrow from "../../../assets/Images/ProductDetailIcon/LeftArrow.svg";
import BXITokenIcon from "../../../assets/Stack of Coins.svg";
import CarasoulForProductDetails from "../../../components/Carousel/CarasoulForProductDetails";
import FeatureName from "../../../components/FeatureName";
import BreadCrumbHeader from "../../../components/Header/BreadCrumbHeader";
import sendEvents from "../../../utils/sendEvents";
import { ProductAnalysisUpdate } from "../../../redux/action/Products/ProductAnalysis";
import { useDispatch } from "react-redux";
import CarouselForProductHorizontal from "../../../components/Carousel/CarouselForProductHorizontal";
import CloseIcon from "@mui/icons-material/Close";
import useGetAuthUser from "../../../Hooks/LoggedInUser/useGetAuthUser";
dayjs.extend(isBetweenPlugin);
dayjs.extend(isBetween);
function ReturnDaysFromTimeline(timeline) {
  if (timeline === "Day") {
    return 0;
  } else if (timeline === "Week") {
    return 6;
  } else if (timeline === "Month") {
    return 29;
  } else if (timeline === "Year") {
    return 364;
  }
}
// return (
const SecondsFieldArr = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170,
  180,
];
function DiscountedPrice({
  regularPrice,
  discountPrice,
  GetProductByIdData,
  percentage,
}) {
  const discount = regularPrice - discountPrice;
  const discountPercent = (discount / regularPrice) * 100;
  const formattedDiscountPercent = discountPercent.toFixed(2);
  const gstPrice = (discountPrice * percentage) / 100;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "row",
          width: "650px",
          mt: "0px",
          marginBottom: "-11px",
          gap: "10px",
        }}
      >
        {formattedDiscountPercent > 0 ? (
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 300,
              fontSize: "15px",
              lineHeight: "10px",
              letterSpacing: "0.06em",
              textTransform: "capitalize",
              color: "#DC3737",
            }}
          >
            -{formattedDiscountPercent}%
          </Typography>
        ) : null}
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "10px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#6B7A99",
          }}
        >
          &nbsp;
          <CommaSeprator Price={discountPrice} />
          <img
            src={BXITokenIcon}
            style={{
              width: "13px",
              height: "auto",
              marginTop: "-4px",
            }}
            alt="BXI Token"
          />{" "}
          + <CommaSeprator Price={gstPrice} /> ₹
          <span
            style={{
              fontSize: "12px",
              fontWeight: 400,
            }}
          >
            GST
          </span>
        </Typography>
        {GetProductByIdData?.ProductsVariantions.at(0)?.unit ? (
          <Typography sx={{ ...fetchValue, pb: 1 }}>
            Per {GetProductByIdData?.ProductsVariantions.at(0)?.unit} Per{" "}
            {GetProductByIdData?.ProductsVariantions.at(0)?.Timeline}
          </Typography>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "15px",
            }}
          >
            <Typography sx={fetchValue}>Per Day</Typography>
            <Typography sx={fetchValue}>Per insertion</Typography>
          </Box>
        )}
      </Box>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "11px",
          lineHeight: "25px",
          letterSpacing: "0.06em",
          textTransform: "capitalize",

          color: "#6B7A99",
          textDecoration: "line-through",
        }}
      >
        MRP: <CommaSeprator Price={regularPrice} />
      </Typography>
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "10px",
          textTransform: "capitalize",
          color: "#6B7A99",
        }}
      >
        Exclusive of all taxes
      </Typography>
    </div>
  );
}

function fetchDatesBetween(startDate, endDate) {
  var dates = [];
  var currentDate = new Date(startDate);
  endDate = new Date(endDate);

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate).toISOString());
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

function filterMultiples(array, multiple) {
  return array.filter(function (value) {
    return value % multiple === 0;
  });
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
}));

function Day(props) {
  const { day, selectedDay, TimelineData, ...other } = props;

  if (selectedDay == null) {
    return <PickersDay day={day} {...other} />;
  }

  const start = selectedDay;
  const end = start.add(ReturnDaysFromTimeline(TimelineData), "day");
  const dayIsBetween = day.isBetween(start, end, null, "[]");
  const isFirstDay = day.isSame(start, "day");
  const isLastDay = day.isSame(end, "day");

  return (
    <CustomPickersDay
      {...other}
      day={day}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
    />
  );
}
const options = { day: "2-digit", month: "short", year: "numeric" };

export default function MediaBuying() {
  let { id } = useParams();
  let ProductId = id;
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [boughtDate, setBoughtDates] = useState([]);
  const [boughtDateNew, setBoughtDatesNew] = useState([]);
  const [unitsToBebought, setUnitsToBebought] = useState(1);
  const [singleUnitProduct, setSingleUnitProsuct] = useState(false);
  const [BoughtSeconds, setBoughtSeconds] = useState(1);

  const [TabValue, setTabValue] = React.useState("1");
  const [sellerId, setSellerId] = useState("");

  const [GetProductByIdData, setGetProductByIdData] = useState();
  const [ProductFeatures, setProfuctFeatures] = useState([]);

  const [storeVariationData, setStoreVariationData] = useState();
  const { data: mutateCartData, mutate: addMediaToCart } = useAddMediaToCart();
  const [cartdata, setCartData] = useState(false);
  const [addToCardView, setAddToCardView] = useState(false);
  const ImageDataArray = GetProductByIdData?.ProductImages;

  const [openCalender, setOpenCalender] = React.useState(false);
  const handleOpenCalender = () => setOpenCalender(true);
  const handleCloseCalender = () => setOpenCalender(false);
  async function GetProductByid() {
    await axios
      .get(
        `product/get_product_byId/${ProductId}
        `,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        if (
          res.data?.ProductSubCategory === "643cdf01779bc024c189cf95" ||
          res.data?.ProductSubCategory === "643ce635e424a0b8fcbba6d6" ||
          res.data?.ProductSubCategory === "643ce648e424a0b8fcbba710" ||
          res.data?.ProductSubCategory === "643ce6fce424a0b8fcbbad42" ||
          res.data?.ProductSubCategory === "643ce707e424a0b8fcbbad4c" ||
          res.data?.ProductSubCategory === "647713dcb530d22fce1f6c36" ||
          res.data?.ProductSubCategory === "643cda0c53068696706e3951"
        ) {
          setSingleUnitProsuct(true);
        }
        setGetProductByIdData(res?.data);
        setSellerId(res?.data?.SellerCompanyId);
        setStoreVariationData(res?.data?.ProductsVariantions[0]?._id);
        setProfuctFeatures(res?.data?.ProductFeatures);
      })
      .catch((err) => {});
  }

  const {
    data: MediaData,

    refetch: Mediarefetch,
  } = useGetMediaCart();

  const { data: AuthUserData } = useGetAuthUser();

  async function GetMediaFromCart() {
    await axios
      .get("media/get_media_cart", {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.body.length > 0) {
          setCartData(true);
        }
      });
  }
  async function GetMediaFromPurchase() {
    try {
      const response = await axios.get(
        `purchase/get_purchase_orderbyproductid`,
        { SellerId: `${sellerId}`, ProductId: `${ProductId}` }
      );
    } catch (error) {}
  }

  function generateRandomId(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  useEffect(() => {
    GetProductByid();
    GetMediaFromCart();
    GetMediaFromPurchase();
  }, []);

  useEffect(() => {
    GetProductByid();
  }, []);

  const countDaysfromTimeline = (value, timeline) => {
    if (timeline === "Week") {
      return value / 7;
    } else if (timeline === "Month") {
      return value / 30;
    } else if (timeline === "Years") {
      return value / 365;
    } else if (timeline === "Day") {
      return value;
    } else if (timeline === "One Time") {
      return value;
    }
  };
  function extractDatesFromObjects(objects) {
    const datesArray = [];

    objects.forEach((obj) => {
      const dates = Object.values(obj).filter((value) => value instanceof Date);
      datesArray.push(...dates);
    });

    return datesArray;
  }
  async function handleAddToCart(id) {
    if (
      AuthUserData?.data?.rightsarray?.includes("purchase") ||
      AuthUserData?.data?.rightsarray?.includes("finance") ||
      AuthUserData?.data?.superAdmin
    ) {
      const datesToBuyArray =
        GetProductByIdData?.ProductsVariantions?.at(0)?.Timeline === "Day" ||
        GetProductByIdData?.ProductsVariantions?.at(0)?.Timeline === "One Time"
          ? boughtDate
          : extractDatesFromObjects(boughtDate);
      if (
        !GetProductByIdData?.ProductCategoryName ===
          "News Papers / Magazines" &&
        datesToBuyArray?.length >
          (ReturnDaysFromTimeline(
            GetProductByIdData?.ProductsVariantions.at(0)?.Timeline
          ) +
            1) *
            Number(
              GetProductByIdData?.ProductsVariantions.at(0)
                ?.maxOrderQuantitytimeline
            )
      ) {
        alert("Dates Should not be more than max Qty");
      } else if (
        !GetProductByIdData?.ProductCategoryName ===
          "News Papers / Magazines" &&
        datesToBuyArray?.length <
          (ReturnDaysFromTimeline(
            GetProductByIdData?.ProductsVariantions.at(0)?.Timeline
          ) +
            1) *
            Number(
              GetProductByIdData?.ProductsVariantions.at(0)
                ?.minOrderQuantitytimeline
            )
      ) {
        alert("Dates Should not be less than min Qty");
      } else {
        if (
          GetProductByIdData?.ProductCategoryName === "MediaOnline" ||
          GetProductByIdData?.ProductCategoryName === "Multiplex ADs"
        ) {
          if (BoughtSeconds < 10) {
            alert("Please Select Minimum 10 Seconds");
          } else {
            await addMediaToCart(
              {
                ProductId: id,
                Boughtdates: datesToBuyArray,
                IsMedia: true,
                IsSample: false,
                ProductQuantity: boughtDate.length,
                unitsToBebought:
                  singleUnitProduct &&
                  GetProductByIdData?.ProductSubCategory !==
                    "647713dcb530d22fce1f6c36"
                    ? 1
                    : singleUnitProduct &&
                      GetProductByIdData?.ProductSubCategory ===
                        "647713dcb530d22fce1f6c36"
                    ? Number(unitsToBebought)
                    : Number(unitsToBebought),
                BoughtSeconds: BoughtSeconds,
                timelineToBought: countDaysfromTimeline(
                  datesToBuyArray.length,
                  GetProductByIdData?.mediaVariation?.Timeline
                ),
              },
              {
                onSuccess: (res) => {
                  dispatch(
                    ProductAnalysisUpdate(
                      ProductId,
                      "",
                      "ProductAddToCardCount"
                    )
                  );

                  toast.success("Added to Cart", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  // }
                  Mediarefetch();
                },
                onError: (err) => {},
              }
            );
            await Mediarefetch();
            await GetMediaFromCart();
            sendEvents("Media Add to cart");
          }
        } else {
          await addMediaToCart(
            {
              ProductId: id,
              Boughtdates: datesToBuyArray,
              IsMedia: true,
              IsSample: false,
              ProductQuantity: boughtDate.length,
              unitsToBebought:
                singleUnitProduct &&
                GetProductByIdData?.ProductSubCategory !==
                  "647713dcb530d22fce1f6c36"
                  ? 1
                  : singleUnitProduct &&
                    GetProductByIdData?.ProductSubCategory ===
                      "647713dcb530d22fce1f6c36"
                  ? Number(unitsToBebought)
                  : Number(unitsToBebought),
              BoughtSeconds: BoughtSeconds,
              timelineToBought: countDaysfromTimeline(
                datesToBuyArray.length,
                GetProductByIdData?.mediaVariation?.Timeline
              ),
            },
            {
              onSuccess: (res) => {
                dispatch(
                  ProductAnalysisUpdate(ProductId, "", "ProductAddToCardCount")
                );

                toast.success("Added to Cart", {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
                // }
                Mediarefetch();
              },
              onError: (err) => {},
            }
          );
          await Mediarefetch();
          await GetMediaFromCart();
          sendEvents("Media Add to cart");
        }
      }
    } else {
      toast.error("You are not authorized to access cart", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  }

  function isDateBetween(targetDate, startDate, endDate) {
    return startDate <= targetDate && targetDate <= endDate;
  }

  const toggle = () => setOpen(!open);

  const [value, setValue] = useState();

  const ableddates = [];
  const getAllDatesOfRange = (startDate, endDate) => {
    let dateIsBetween = false;

    for (const item of boughtDate) {
      if (isDateBetween(item, startDate, endDate)) {
        dateIsBetween = true;
        break;
      }
    }

    if (dateIsBetween) {
      return [];
    }

    const theDate = new Date(startDate);
    while (theDate <= endDate) {
      ableddates.push(new Date(theDate));
      theDate.setDate(theDate.getDate() + 1);
    }

    return ableddates;
  };

  GetProductByIdData?.calender?.map((item, idx) => {
    getAllDatesOfRange(new Date(item.startDate), new Date(item.endDate));
  });

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/product/GetOwnProducts?productId=${id}`,
        {
          withCredentials: true,
        }
      );
      setAddToCardView(response.data);
    } catch (error) {}
  };
  fetchData();

  return (
    <React.Fragment>
      <Paper
        Paper
        sx={{
          width: "100%",
          height: "100%",
          background: "transparent",
          boxShadow: "none",
        }}
        elevation={0}
      >
        <BreadCrumbHeader MainText="Media" showbreadcrumb={true} />

        <Box
          sx={{
            padding: "1% 0",
            borderRadius: "30px",
            margin: "0%",
            background: "#fff",
            height: "auto",
            width: "auto",
            mt: "20px",
          }}
        >
          {/* Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textAlign: "center",
              width: "95%",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PreviewPageHeader MediaBuying={true} />
            </Box>
          </Box>

          {/* ***** Carasoul ***** */}
          <Grid container sx={{ mt: 1 }}>
            <Grid item xl={4} lg={4} md={4}>
              <Box style={{ width: "532px", maxHeight: "432px" }}>
                <CarouselForProductHorizontal ImageDataArray={ImageDataArray} />
              </Box>
            </Grid>
            <Grid item xl={1} lg={1} md={1}></Grid>
            <Grid item xl={7} lg={7} md={7}>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{}}>
                  <Box>
                    <Typography
                      sx={{ ...semi, color: "#4D4D4D", fontSize: "20px" }}
                    >
                      {GetProductByIdData?.ProductName}
                    </Typography>
                    <Typography
                      sx={{
                        ...semi,
                        color: "#6B7A99",
                        textAlign: "justify",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                      }}
                    >
                      {GetProductByIdData?.SellerCompanyName}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  borderBottom: "0.1px solid #8C8C8C",
                  mt: 1,
                  width: "98%",
                }}
              ></Box>
              <Box sx={{ mt: 0.5 }}>
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <DiscountedPrice
                    regularPrice={
                      GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.PricePerUnit
                    }
                    discountPrice={
                      GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.DiscountedPrice
                    }
                    GetProductByIdData={GetProductByIdData}
                    percentage={
                      GetProductByIdData?.ProductsVariantions?.at(0)?.GST
                    }
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  borderBottom: "0.1px solid #8C8C8C",
                  mt: 1.5,
                  width: "98%",
                }}
              ></Box>
              <Box sx={{ mt: 1 }}>
                <Typography
                  sx={{
                    ...tableHeader,
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "17px",
                    color: "#156DB6",
                  }}
                >
                  Inventory Available Dates
                </Typography>

                <Box
                  onClick={toggle}
                  sx={{
                    background: "rgba(243, 246, 249, 0.4)",
                    width: "255px",
                    // height: "53px",
                    borderRadius: "10px",
                    mt: "0.8%",
                    padding: "1rem",
                    height:
                      GetProductByIdData?.calender?.length < 10
                        ? "auto"
                        : "150px",
                    overflowY: "auto",
                    // display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      overflow: "auto",
                      "::-webkit-scrollbar": {
                        display: "flex",
                      },
                      "::-webkit-scrollbar-thumb": {
                        dynamic: "#8d8e90",
                        minHeight: "10px",
                        borderRadius: "8px",
                      },
                      "::-webkit-scrollbar-thumb:vertical": {
                        miaxHeight: "10px",
                      },
                      maxHeight:
                        GetProductByIdData?.calender?.length < 10
                          ? "auto"
                          : "150px",
                      height:
                        GetProductByIdData?.calender?.length < 10
                          ? "auto"
                          : "160px",
                    }}
                  >
                    <Stack>
                      {GetProductByIdData?.calender?.map((item, idx) => {
                        return (
                          <>
                            <Typography
                              sx={{ fontSize: "12px", color: "grey" }}
                            >
                              from :
                              {new Date(item?.startDate).toLocaleDateString(
                                "en-US",
                                options
                              )}{" "}
                              - to :
                              {new Date(item?.endDate).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </Typography>
                          </>
                        );
                      })}
                    </Stack>
                  </Box>
                </Box>
              </Box>
              <Grid container mt={2}>
                <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
                  <Typography
                    sx={{
                      ...tableHeader,
                      fontWeight: 600,
                      fontSize: "12px",
                      lineHeight: "17px",
                      color: "#156DB6",
                    }}
                  >
                    {GetProductByIdData?.ProductsVariantions.at(0)?.Timeline} to
                    buy
                  </Typography>
                  <Box
                    onClick={handleOpenCalender}
                    sx={{
                      width: "60%",
                      height: "8px",
                      mt: 1,
                      cursor: "pointer",
                      // border: " 1.22591px solid #D4D4D4",
                      // borderRadius: "5px",
                      background: "#fff",
                      boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                      borderRadius: "12px 12px 12px 12px",
                      color: "grey",
                      p: 2,
                      color: "#156DB6",
                    }}
                  >
                    Select
                  </Box>

                  <Modal
                    open={openCalender}
                    onClose={handleCloseCalender}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    fullWidth
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#fff",
                        width: "20%",
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        borderRadius: "5px",
                        mt: 5,
                        p: 2,
                      }}
                    >
                      {GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.Timeline === "Day" ||
                      GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.Timeline === "One Time" ? (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                              mt: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                ...tableHeader,
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#156DB6",
                              }}
                            >
                              Select Date :
                            </Typography>
                            <Box
                              onClick={() => setOpenCalender(false)}
                              sx={CloseButtonStyle}
                            >
                              <CloseIcon fontSize="large" />
                            </Box>
                          </Box>
                          <DatePicker
                            label="Select Dates"
                            sx={{
                              width: "90%",
                              mt: 1,
                            }}
                            shouldDisableDate={(date) => {
                              const isDateInRange =
                                GetProductByIdData?.calender?.find((el) => {
                                  const dateIsInRange = isDateBetween(
                                    new Date(date),
                                    new Date(el.startDate),
                                    new Date(el.endDate)
                                  );

                                  return dateIsInRange && !el.OutOfStock;
                                });

                              const boughtDates = boughtDateNew.map((dateStr) =>
                                new Date(dateStr).toISOString()
                              );

                              return (
                                !isDateInRange ||
                                boughtDates.includes(date.toISOString())
                              );
                            }}
                            disablePast={true}
                            onChange={(e) => {
                              toast.success(
                                `${new Date(
                                  e
                                ).toLocaleDateString()} Date Selected `,
                                {
                                  position: "top-center",
                                  autoClose: 5000,
                                  hideProgressBar: true,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                }
                              );
                              const data = boughtDate?.find((item) =>
                                dayjs(item).isSame(e)
                              );
                              if (data) {
                                return;
                              }

                              setBoughtDates([...boughtDate, new Date(e)]);
                            }}
                          />
                        </LocalizationProvider>
                      ) : (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "10px",
                              mt: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                ...tableHeader,
                                fontWeight: 600,
                                fontSize: "18px",
                                color: "#156DB6",
                              }}
                            >
                              Select{" "}
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.Timeline
                              }{" "}
                              :
                            </Typography>
                            <Box
                              onClick={() => setOpenCalender(false)}
                              sx={CloseButtonStyle}
                            >
                              <CloseIcon fontSize="large" />
                            </Box>
                          </Box>
                          <DateCalendar
                            sx={{
                              "& .MuiPickersDay-day": {
                                color: "red",
                              },
                              "& .MuiPickersDay-daySelected": {
                                color: "green",
                              },
                              "& .MuiPickersDay-dayDisabled": {
                                color: "pink",
                              },
                              width: "90%",
                            }}
                            value={value}
                            disablePast={true}
                            shouldDisableDate={(date) => {
                              const isDateInRange =
                                GetProductByIdData?.calender?.find((el) => {
                                  const dateIsInRange = isDateBetween(
                                    new Date(date),
                                    new Date(el.startDate),
                                    new Date(el.endDate)
                                  );

                                  return dateIsInRange && !el.OutOfStock;
                                });

                              const boughtDates = boughtDateNew.map((dateStr) =>
                                new Date(dateStr).toISOString()
                              );

                              return (
                                !isDateInRange ||
                                boughtDates.includes(date.toISOString())
                              );
                            }}
                            slots={{ day: Day }}
                            slotProps={{
                              day: {
                                selectedDay: value,
                                TimelineData:
                                  GetProductByIdData?.ProductsVariantions?.at(0)
                                    ?.Timeline,
                              },
                            }}
                            onChange={(newValue) => {
                              if (
                                GetProductByIdData?.ProductSubCategory ===
                                  "643cda0c53068696706e3951" &&
                                newValue.day() !== 5
                              ) {
                                toast.error(
                                  "Only Fridays are allowed for selection.",
                                  {
                                    position: "top-center",
                                    autoClose: 2000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  }
                                );
                                return;
                              }
                              setValue(newValue);
                              let enddate = newValue.add(
                                ReturnDaysFromTimeline(
                                  GetProductByIdData?.ProductsVariantions?.at(0)
                                    ?.Timeline
                                ),
                                "day"
                              );

                              let DatesArray = fetchDatesBetween(
                                new Date(newValue),
                                new Date(enddate)
                              );

                              if (ableddates && DatesArray) {
                                const matchingDates = ableddates.filter(
                                  (date) =>
                                    DatesArray.includes(date.toISOString())
                                );

                                if (
                                  matchingDates.length === DatesArray?.length
                                ) {
                                  let IdString = generateRandomId(8);

                                  const duplicateDates = matchingDates.some(
                                    (newDate) =>
                                      boughtDate.some((obj) => {
                                        for (let i = 0; i <= 6; i++) {
                                          if (
                                            obj[i] &&
                                            obj[i].getTime() ===
                                              new Date(newDate).getTime()
                                          ) {
                                            return true;
                                          }
                                        }
                                        return false;
                                      })
                                  );

                                  if (duplicateDates) {
                                    toast.error(
                                      "One or more dates already exist in selected dates please select another date.",
                                      {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                      }
                                    );
                                    return;
                                  } else {
                                    toast.success(
                                      `${new Date(
                                        newValue
                                      ).toLocaleDateString()} to ${new Date(
                                        enddate
                                      ).toLocaleDateString()} TimeLine Selected`,
                                      {
                                        position: "top-center",
                                        autoClose: 5000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                      }
                                    );
                                  }

                                  setBoughtDates([
                                    ...boughtDate,
                                    { ...matchingDates, IdString },
                                  ]);
                                } else {
                                  toast.error(
                                    "One or more of the selected dates is not available to buy. Please select another date.",
                                    {
                                      position: "top-center",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "light",
                                    }
                                  );
                                }
                              }
                            }}
                          />
                        </LocalizationProvider>
                      )}
                    </Box>
                  </Modal>
                </Grid>
                {singleUnitProduct &&
                GetProductByIdData?.ProductSubCategory !==
                  "647713dcb530d22fce1f6c36" ? null : singleUnitProduct &&
                  GetProductByIdData?.ProductSubCategory ===
                    "647713dcb530d22fce1f6c36" ? null : (
                  <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
                    <Box
                      sx={{
                        width: "100%",
                        ml: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          ...tableHeader,
                          fontWeight: 600,
                          fontSize: "12px",
                          lineHeight: "17px",
                          color: "#156DB6",
                        }}
                      >
                        Units to Buy
                      </Typography>

                      <TextField
                        id=""
                        sx={{
                          "& fieldset": { border: "none" },
                          width: "70%",
                          height: "42px",
                          mt: 1,
                          background: "#fff",
                          boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                          borderRadius: "12px 12px 12px 12px",
                        }}
                        value={unitsToBebought}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (
                            inputValue.includes(".") ||
                            e.nativeEvent.inputType === "insertFromPaste"
                          ) {
                            e.preventDefault();
                            return;
                          }
                          setUnitsToBebought(inputValue);
                        }}
                      />
                    </Box>
                  </Grid>
                )}

                <Grid xl={4} lg={4} md={4} sm={4} xs={4}>
                  {GetProductByIdData?.ProductsVariantions.at(0)
                    ?.maxTimeslotSeconds ? (
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          ...tableHeader,
                          fontWeight: 600,
                          fontSize: "14px",
                          lineHeight: "17px",
                          color: "#156DB6",
                        }}
                      >
                        Seconds to buy
                      </Typography>
                      <Select
                        sx={{
                          width: "70%",
                          mt: 1,
                          "& fieldset": { border: "none" },
                          background: "#fff",
                          boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                          borderRadius: "12px 12px 12px 12px",
                        }}
                        onChange={(e) => {
                          setBoughtSeconds(e.target.value);
                        }}
                        defaultValue={120}
                      >
                        {filterMultiples(
                          SecondsFieldArr,
                          GetProductByIdData?.ProductsVariantions.at(0)
                            ?.minTimeslotSeconds
                        )?.map((item, idx) => {
                          if (
                            Number(
                              GetProductByIdData?.Inventory
                                ? GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.maxTimeslotSeconds -
                                    GetProductByIdData?.Inventory?.BoughtSeconds
                                : GetProductByIdData?.ProductsVariantions.at(0)
                            ) < Number(item)
                          )
                            return null;
                          if (
                            Number(
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxTimeslotSeconds
                            ) < Number(item)
                          )
                            return null;
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      </Select>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: "90%",
                  borderRadius: "10px",
                  mt: 2,
                }}
              >
                {MediaData?.body?.find(
                  (item) => item?.ProductId?._id === ProductId
                ) ? (
                  <Button
                    variant="contained"
                    sx={{
                      ...CartButtonStyle,
                      width: "auto",
                      minWidth: "210px",
                      height: "40px",
                      fontSize: "16px",
                      borderRadius: "12px",
                      boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                    }}
                    onClick={() =>
                      navigate("/home/cart", {
                        state: 1,
                      })
                    }
                  >
                    Go To Cart
                  </Button>
                ) : (
                  addToCardView === false && (
                    <Button
                      variant="contained"
                      sx={{
                        ...CartButtonStyle,
                        width: "auto",
                        minWidth: "210px",
                        height: "40px",
                        fontSize: "16px",
                        borderRadius: "12px",
                        boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
                      }}
                      onClick={() => {
                        if (singleUnitProduct) {
                          if (boughtDate?.length === 0) {
                            alert("Please Select Date");
                          } else if (
                            (GetProductByIdData?.ProductSubCategory ===
                              "647713dcb530d22fce1f6c36" &&
                              Number(unitsToBebought) <
                                Number(
                                  GetProductByIdData?.ProductsVariantions?.at(0)
                                    ?.minOrderQuantitytimeline
                                )) ||
                            Number(unitsToBebought) >
                              Number(
                                GetProductByIdData?.ProductsVariantions?.at(0)
                                  ?.maxOrderQuantitytimeline
                              )
                          ) {
                            alert(
                              "Units Should not be less than or greater than max Qty"
                            );
                          } else {
                            handleAddToCart(ProductId);
                          }
                        } else {
                          if (boughtDate?.length === 0) {
                            alert("Please Select Date");
                          } else if (unitsToBebought === 0) {
                            alert("Please Select Units");
                          } else if (
                            Number(unitsToBebought) >
                            Number(
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.maxOrderQuantityunit -
                                GetProductByIdData?.Inventory?.Quantity
                            )
                          ) {
                            alert("Units Should not be more than max Qty");
                          } else if (
                            Number(unitsToBebought) <
                            Number(
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.minOrderQuantityunit
                            )
                          ) {
                            alert("Units Should not be less than min Qty");
                          } else if (
                            GetProductByIdData?.ProductSubCategory ===
                              "65029534eaa5251874e8c6b4" &&
                            Number(unitsToBebought) !==
                              Number(
                                GetProductByIdData?.ProductsVariantions?.at(0)
                                  ?.maxOrderQuantityunit
                              )
                          ) {
                            alert(
                              "Units Should not be less than or greater than max Qty"
                            );
                          } else {
                            handleAddToCart(ProductId);
                          }
                        }
                      }}
                    >
                      Add to cart
                    </Button>
                  )
                )}
              </Box>
              <Box mt={1.5}>
                <Typography
                  sx={{
                    ...tableHeader,
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#156DB6",
                  }}
                >
                  Selected Dates
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "95%",
                    maxHeight: "100px",
                    gap: "20px",
                    mt: "0.2%",
                    overflow: "auto",
                    "::-webkit-scrollbar": {
                      display: "flex",
                      height: "6px",
                    },
                  }}
                >
                  {GetProductByIdData?.ProductsVariantions?.at(0)?.Timeline ===
                    "Day" ||
                  GetProductByIdData?.ProductsVariantions?.at(0)?.Timeline ===
                    "One Time"
                    ? boughtDate?.map((item, idx) => {
                        return (
                          <>
                            <Box
                              sx={{
                                background: "rgba(243, 246, 249, 0.4)",
                                padding: "10px",
                                borderRadius: "10px",
                                textAlign: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  ...selectdate,
                                }}
                              >
                                {new Date(item).toDateString()}
                              </Typography>
                              <Button
                                onClick={() => {
                                  setBoughtDates(
                                    boughtDate.filter(
                                      (item, index) => index !== idx
                                    )
                                  );
                                }}
                              >
                                {" "}
                                X
                              </Button>
                            </Box>
                          </>
                        );
                      })
                    : boughtDate?.map((item) => {
                        return (
                          <Box
                            sx={{
                              background: "rgba(243, 246, 249, 0.4)",
                              padding: "10px",
                              borderRadius: "10px",
                              textAlign: "center",
                            }}
                          >
                            {Object.entries(item).map(
                              ([key, value], index, array) => {
                                if (
                                  index === 0 ||
                                  index === array.length - 2 ||
                                  index === array.length - 1
                                ) {
                                  if (key === "IdString") {
                                    return (
                                      <Button
                                        onClick={() => {
                                          setBoughtDates(
                                            boughtDate.filter(
                                              (item) => item.IdString !== value
                                            )
                                          );
                                        }}
                                      >
                                        X
                                      </Button>
                                    );
                                  } else {
                                    if (index === 0) {
                                      return (
                                        <Typography
                                          sx={{
                                            ...selectdate,
                                          }}
                                        >
                                          From :{" "}
                                          {new Date(value).toDateString()}
                                        </Typography>
                                      );
                                    } else if (index === array.length - 2) {
                                      return (
                                        <Typography
                                          sx={{
                                            ...selectdate,
                                          }}
                                        >
                                          To : {new Date(value).toDateString()}
                                        </Typography>
                                      );
                                    }
                                  }
                                }
                                return null;
                              }
                            )}
                          </Box>
                        );
                      })}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <TabContext value={TabValue} variant="fullwidth">
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                mt: {
                  xl: "40px",
                  lg: "40px",
                  md: "30px",
                  sm: "20px",
                  xs: "10px",
                },
              }}
            ></Box>

            <TabPanel value="1">
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{}}>
                  <Box>
                    <Typography sx={{ ...TypographyTitleText }}>
                      {GetProductByIdData?.ProductSubtitle}
                    </Typography>
                    <Typography sx={DescriptionAnswerText}>
                      {GetProductByIdData?.ProductDescription}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>{" "}
              <Grid container>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ mt: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <Box mt={2}>
                      <Grid container sx={{ width: "100%" }}>
                        {GetProductByIdData?.medianame ? (
                          <Grid
                            item
                            xl={2.4}
                            lg={2.4}
                            md={2.4}
                            sm={2.4}
                            xs={2.4}
                          >
                            <Typography sx={tableHeader}>Media Name</Typography>
                            <Typography sx={fetchValue}>
                              {GetProductByIdData?.medianame}
                            </Typography>
                          </Grid>
                        ) : null}
                        {GetProductByIdData?.multiplexScreenName ? (
                          <Grid
                            item
                            xl={2.4}
                            lg={2.4}
                            md={2.4}
                            sm={2.4}
                            xs={2.4}
                          >
                            <Typography sx={tableHeader}>
                              Multiplex Name
                            </Typography>
                            <Typography sx={fetchValue}>
                              {GetProductByIdData?.multiplexScreenName}
                            </Typography>
                          </Grid>
                        ) : null}
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          {GetProductByIdData?.offerningbrandat ? (
                            <>
                              <Typography sx={tableHeader}>
                                {" "}
                                Offering At
                              </Typography>
                              <Typography sx={fetchValue}>
                                {GetProductByIdData?.offerningbrandat}
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography sx={tableHeader}>
                                {" "}
                                Position of the Ad ?
                              </Typography>
                              <Typography sx={fetchValue}>
                                {GetProductByIdData?.adPosition}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mt: 3, width: "100%" }}>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.location ? (
                          <Grid item xl={2.4} lg={2.4} md={2.4} sm={2} xs={2}>
                            <Typography sx={tableHeader}>
                              Media Location
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.location
                              }
                            </Typography>
                          </Grid>
                        ) : null}
                        {GetProductByIdData?.ProductsVariantions.at(0)?.Type ? (
                          <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                            <Typography sx={tableHeader}>Type</Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.Type
                              }
                            </Typography>
                          </Grid>
                        ) : null}
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          {GetProductByIdData?.ProductsVariantions.at(0)
                            ?.Timeline ? (
                            <>
                              <Typography sx={tableHeader}>
                                {" "}
                                Timeline
                              </Typography>
                              <Typography sx={fetchValue}>
                                {
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.Timeline
                                }
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography sx={tableHeader}> Edition</Typography>
                              <Typography sx={fetchValue}>
                                {
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.edition
                                }
                              </Typography>
                            </>
                          )}
                        </Grid>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.edition ? (
                          <>
                            <Grid
                              item
                              xl={2.4}
                              lg={2.4}
                              md={2.4}
                              sm={2.4}
                              xs={2.4}
                            >
                              <Typography sx={tableHeader}> Edition</Typography>
                              <Typography sx={fetchValue}>
                                {
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.edition
                                }
                              </Typography>
                            </Grid>
                          </>
                        ) : null}

                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          {GetProductByIdData?.ProductsVariantions.at(0)
                            ?.unit ? (
                            <>
                              <Typography sx={tableHeader}>Unit</Typography>
                              <Typography sx={fetchValue}>
                                {
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.unit
                                }
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography sx={tableHeader}>
                                Release Details
                              </Typography>
                              <Typography sx={fetchValue}>
                                {
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.releasedetails
                                }
                              </Typography>
                            </>
                          )}
                        </Grid>
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          {GetProductByIdData?.ProductsVariantions.at(0)
                            ?.repetition ? (
                            <>
                              <Typography sx={tableHeader}>
                                {" "}
                                Repetition
                              </Typography>
                              <Typography sx={fetchValue}>
                                {
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.repetition
                                }
                              </Typography>
                            </>
                          ) : (
                            <>
                              <Typography sx={tableHeader}>Ad Type</Typography>
                              <Typography sx={fetchValue}>
                                {
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.adType
                                }
                              </Typography>
                            </>
                          )}
                        </Grid>
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          <Typography sx={tableHeader}>
                            Dimension Size
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.dimensionSize
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mt: 2, width: "100%" }}>
                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          <Typography sx={tableHeader}>Price</Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.DiscountedPrice
                            }
                          </Typography>
                        </Grid>

                        <Grid item xl={2.4} lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                          <Typography sx={tableHeader}>GST</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.ProductsVariantions.at(0)?.GST}{" "}
                            %
                          </Typography>
                        </Grid>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.availableInsertions ? (
                          <Grid
                            item
                            xl={2.4}
                            lg={2.4}
                            md={2.4}
                            sm={2.4}
                            xs={2.4}
                          >
                            <Typography sx={tableHeader}>
                              Available Insertions
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.availableInsertions
                              }{" "}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                      <Grid container sx={{ mt: 3, width: "90%" }}>
                        <Grid
                          item
                          xl={2.65}
                          lg={2.65}
                          md={2.65}
                          sm={2.65}
                          xs={2.65}
                        >
                          <Typography sx={tableHeader}>Region</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.GeographicalData?.region}
                          </Typography>
                        </Grid>
                        {GetProductByIdData?.GeographicalData?.state ===
                          undefined ||
                        GetProductByIdData?.GeographicalData?.state === null ||
                        GetProductByIdData?.GeographicalData?.state ===
                          "" ? null : (
                          <Grid
                            item
                            xl={2.65}
                            lg={2.65}
                            md={2.65}
                            sm={2.65}
                            xs={2.65}
                          >
                            <Typography sx={tableHeader}> State</Typography>
                            <Typography sx={fetchValue}>
                              {GetProductByIdData?.GeographicalData?.state}
                            </Typography>
                          </Grid>
                        )}
                        {GetProductByIdData?.GeographicalData?.city ===
                          undefined ||
                        GetProductByIdData?.GeographicalData?.city === null ||
                        GetProductByIdData?.GeographicalData?.city ===
                          "" ? null : (
                          <Grid
                            item
                            xl={2.65}
                            lg={2.65}
                            md={2.65}
                            sm={2.65}
                            xs={2.65}
                          >
                            <Typography sx={tableHeader}>City</Typography>
                            <Typography sx={fetchValue}>
                              {GetProductByIdData?.GeographicalData?.city}
                            </Typography>
                          </Grid>
                        )}
                        {GetProductByIdData?.GeographicalData?.landmark ===
                          undefined ||
                        GetProductByIdData?.GeographicalData?.landmark ===
                          null ||
                        GetProductByIdData?.GeographicalData?.landmark ===
                          "" ? null : (
                          <Grid
                            item
                            xl={2.65}
                            lg={2.65}
                            md={2.65}
                            sm={2.65}
                            xs={2.65}
                          >
                            <Typography sx={tableHeader}> Landmark</Typography>
                            <Typography sx={fetchValue}>
                              {GetProductByIdData?.GeographicalData?.landmark}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid container sx={{ mt: 3 }}>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.minOrderQuantitytimeline ? (
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                            <Typography sx={tableHeader}>
                              {" "}
                              Min - Max Order Quantity Timeline
                            </Typography>
                            <Typography sx={fetchValue}>
                              {GetProductByIdData?.ProductsVariantions.at(0)
                                ?.minOrderQuantitytimeline
                                ? `${
                                    GetProductByIdData?.ProductsVariantions.at(
                                      0
                                    )?.minOrderQuantitytimeline
                                  } - ${
                                    GetProductByIdData?.Inventory
                                      ? GetProductByIdData?.ProductsVariantions?.at(
                                          0
                                        )?.maxOrderQuantitytimeline -
                                        GetProductByIdData?.Inventory
                                          ?.TimelineToBought
                                      : GetProductByIdData?.ProductsVariantions?.at(
                                          0
                                        ).maxOrderQuantitytimeline
                                  }`
                                : "N/A"}{" "}
                              {""} /{" "}
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.Timeline
                              }
                            </Typography>
                          </Grid>
                        ) : null}

                        {GetProductByIdData?.ProductSubCategory ===
                        "643cda0c53068696706e3951" ? null : (
                          <Box>
                            {GetProductByIdData?.ProductsVariantions?.at(0)
                              ?.minOrderQuantityunit ? (
                              <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                                <Typography
                                  sx={{ ...tableHeader, width: "400px" }}
                                >
                                  {" "}
                                  Min - Max Order Quantity Unit
                                </Typography>
                                <Typography sx={fetchValue}>
                                  {
                                    GetProductByIdData?.ProductsVariantions?.at(
                                      0
                                    )?.minOrderQuantityunit
                                  }{" "}
                                  -
                                  {
                                    GetProductByIdData?.ProductsVariantions?.at(
                                      0
                                    )?.maxOrderQuantityunit
                                  }
                                  /{" "}
                                  {
                                    GetProductByIdData?.ProductsVariantions.at(
                                      0
                                    )?.unit
                                  }
                                </Typography>
                              </Grid>
                            ) : null}
                          </Box>
                        )}

                        {GetProductByIdData?.ProductsVariantions?.at(0)
                          ?.minTimeslotSeconds ? (
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                            <Typography sx={tableHeader}>
                              {" "}
                              Min - Max Timeslot
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.minTimeslotSeconds
                              }{" "}
                              -
                              {GetProductByIdData?.Inventory?.BoughtSeconds
                                ? GetProductByIdData?.ProductsVariantions?.at(0)
                                    ?.maxTimeslotSeconds -
                                  GetProductByIdData?.Inventory?.BoughtSeconds
                                : GetProductByIdData?.ProductsVariantions?.at(0)
                                    .maxTimeslotSeconds}
                              / Seconds {""}{" "}
                            </Typography>
                          </Grid>
                        ) : null}
                        {GetProductByIdData?.ProductsVariantions?.at(0)
                          ?.seatingCapacity ? (
                          <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                            <Typography sx={tableHeader}>
                              {" "}
                              Seating Capacity
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions?.at(0)
                                  ?.seatingCapacity
                              }
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexdirection: "row",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "35%",
                        }}
                      >
                        <Typography
                          sx={{
                            ...tableHeader,
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "27px",
                          }}
                        >
                          GST
                        </Typography>
                        <Box
                          sx={{
                            background: "rgba(243, 246, 249, 0.4)",
                            width: "315px",
                            height: "53px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            px: 1,
                            mt: "0.8%",
                          }}
                        >
                          <Typography
                            sx={{
                              ...tableData,
                              fontWeight: 500,
                              color: "#445FD2",
                            }}
                          >
                            {GetProductByIdData?.mediaVariation?.GST}%
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "35%",
                        }}
                      >
                        <Typography
                          sx={{
                            ...tableHeader,
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "27px",
                          }}
                        >
                          Total{" "}
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.Timeline
                          }
                        </Typography>
                        <Box
                          sx={{
                            background: "rgba(243, 246, 249, 0.4)",
                            width: "315px",
                            height: "53px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            px: 1,
                            mt: "0.8%",
                          }}
                        >
                          <Typography
                            sx={{
                              ...tableData,
                              color: "#445FD2",
                            }}
                          >
                            {boughtDate?.length === 0
                              ? "0"
                              : boughtDate?.length}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          width: "35%",
                        }}
                      >
                        <Typography
                          sx={{
                            ...tableHeader,
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "27px",
                          }}
                        >
                          Total Price
                        </Typography>
                        <Box
                          sx={{
                            background: "rgba(243, 246, 249, 0.4)",
                            width: "315px",
                            height: "53px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            px: 1,
                            mt: "0.8%",
                          }}
                        >
                          <Typography
                            sx={{
                              ...tableData,
                              fontWeight: 500,
                              display: "flex",
                              flexDirection: "raw",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              color: "#445FD2",
                            }}
                          >
                            {boughtDate?.length === 0 || unitsToBebought === 0
                              ? GetProductByIdData?.mediaVariation
                                  ?.DiscountedPrice
                              : GetProductByIdData?.mediaVariation
                                  ?.minTimeslotSeconds
                              ? (GetProductByIdData?.mediaVariation
                                  ?.DiscountedPrice *
                                  boughtDate?.length *
                                  unitsToBebought *
                                  BoughtSeconds) /
                                10
                              : GetProductByIdData?.mediaVariation
                                  ?.DiscountedPrice *
                                boughtDate?.length *
                                unitsToBebought *
                                BoughtSeconds}
                            <Box
                              component="img"
                              src={BXITokenIcon}
                              sx={{ height: "18px", width: "18px" }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "35%",
                      }}
                    >
                      <Typography
                        sx={{
                          ...tableHeader,
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "27px",
                        }}
                      >
                        Total GST
                      </Typography>
                      <Box
                        sx={{
                          background: "rgba(243, 246, 249, 0.4)",
                          width: "315px",
                          height: "53px",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          px: 1,
                          mt: "0.8%",
                        }}
                      >
                        {GetProductByIdData?.ProductsVariantions?.map(
                          (res, idx) => {
                            if (res?._id === storeVariationData) {
                              let total =
                                boughtDate?.length === 0 ||
                                unitsToBebought === 0
                                  ? GetProductByIdData?.mediaVariation
                                      ?.DiscountedPrice
                                  : GetProductByIdData?.mediaVariation
                                      ?.minTimeslotSeconds
                                  ? (GetProductByIdData?.mediaVariation
                                      ?.DiscountedPrice *
                                      boughtDate?.length *
                                      unitsToBebought *
                                      BoughtSeconds) /
                                    10
                                  : GetProductByIdData?.mediaVariation
                                      ?.DiscountedPrice *
                                    boughtDate?.length *
                                    unitsToBebought *
                                    BoughtSeconds;

                              const gstPrice = (total * res?.GST) / 100;
                              return (
                                <Box
                                  sx={{
                                    background: "rgba(243, 246, 249, 0.4)",
                                    width: "315px",
                                    height: "53px",
                                    borderRadius: "10px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    px: 1,
                                    mt: "0.8%",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      ...tableData,
                                      fontWeight: 500,
                                      display: "flex",
                                      flexDirection: "raw",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                      color: "#445FD2",
                                    }}
                                  >
                                    <CommaSeparator Price={gstPrice} />

                                    <Box
                                      sx={{
                                        fontSize: "20px",
                                      }}
                                    >
                                      &nbsp; ₹
                                    </Box>
                                  </Typography>
                                </Box>
                              );
                            }
                          }
                        )}
                      </Box>
                    </Box>

                    {GetProductByIdData?.OtherCost &&
                    GetProductByIdData?.OtherCost?.length !== 0 ? (
                      <Box mt={2}>
                        <Typography
                          sx={{
                            ...product,
                            fontWeight: 600,
                            fontSize: "18px",
                            lineHeight: "15px",
                            color: "#156DB6",
                          }}
                        >
                          Additional Cost
                        </Typography>
                        {GetProductByIdData?.OtherCost?.length === 0
                          ? ""
                          : GetProductByIdData?.OtherCost?.map((cost) => {
                              const newValue = cost?.CostPrice.toFixed(2);
                              return (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "40px",
                                      mt: 0.5,
                                    }}
                                  >
                                    <TableContainer>
                                      <Table
                                        size="small"
                                        aria-label="a dense table"
                                      >
                                        <TableBody>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                minWidth: "160px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  ...fetchValue,
                                                  color: "grey",
                                                }}
                                              >
                                                {" "}
                                                &#8226; &nbsp;&nbsp;{" "}
                                                {cost?.ReasonOfCost}{" "}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                            }}
                                          >
                                            {!cost?.AdCostHSN ? null : (
                                              <Box
                                                sx={{
                                                  minWidth: "160px",
                                                }}
                                              >
                                                <Typography
                                                  sx={{
                                                    ...fetchValue,
                                                    color: "grey",
                                                  }}
                                                >
                                                  HSN - {cost?.AdCostHSN}{" "}
                                                </Typography>
                                              </Box>
                                            )}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                minWidth: "160px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  ...fetchValue,
                                                  color: "grey",
                                                }}
                                              >
                                                GST - {cost?.AdCostGST} %
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                minWidth: "160px",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  ...fetchValue,
                                                  color: "grey",
                                                }}
                                              >
                                                {cost?.AdCostApplicableOn ===
                                                "All"
                                                  ? "One Time Cost"
                                                  : "Per Unit"}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              borderBottom: "none",
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                gap: "5px",
                                                minWidth: "160px",
                                                display: "flex",
                                              }}
                                            >
                                              <Typography>
                                                {cost.currencyType ===
                                                "BXITokens" ? (
                                                  <Box
                                                    component="img"
                                                    src={BXITokenIcon}
                                                    alt="token"
                                                    sx={{
                                                      height: "auto",
                                                      width: "15px",
                                                      marginTop: "10px",
                                                    }}
                                                  />
                                                ) : (
                                                  <Typography
                                                    sx={{
                                                      fontSize: "20px",
                                                      ml: 0.5,
                                                      color: "#156DB6",
                                                      mt: 0.5,
                                                    }}
                                                  >
                                                    ₹
                                                  </Typography>
                                                )}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  ...fetchValue,
                                                  color: "grey",
                                                }}
                                              >
                                                {newValue}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                </>
                              );
                            })}
                      </Box>
                    ) : null}
                    {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks
                      ?.length !== 0 ? (
                      <Box sx={{ mt: 1 }}>
                        <Typography sx={{ ...cost, color: "#156DB6" }}>
                          Remarks{" "}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            width: "95%",
                            gap: "10px",
                            mt: 0.5,
                          }}
                        >
                          {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks?.map(
                            (el, idx) => {
                              return (
                                <>
                                  <Typography sx={otherCostText}>
                                    {idx + 1}.
                                  </Typography>
                                  <Typography sx={otherCostText}>
                                    {el}
                                  </Typography>
                                </>
                              );
                            }
                          )}
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ borderTop: "0.2px solid #156DB6", mt: 2 }}>
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ ...pack, color: "#156DB6" }}>
                    Technical Information
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "2px",
                    }}
                  >
                    <Typography sx={{ ...inclusiveheader, color: "#156DB6" }}>
                      Support you would get from Seller
                    </Typography>
                    {GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                      ? Object?.keys(
                          GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                        ).map((el, idx) => {
                          if (
                            GetProductByIdData
                              ?.WhatSupportingYouWouldGiveToBuyer[el] === "on"
                          ) {
                            return (
                              <>
                                <Typography
                                  sx={{
                                    ...packHead,
                                    color: "grey",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    display: "flex",
                                    gap: "15px",
                                  }}
                                >
                                  {" "}
                                  {el}
                                </Typography>
                              </>
                            );
                          } else {
                            return null;
                          }
                        })
                      : null}{" "}
                  </Box>

                  <Box>
                    <Typography sx={{ ...inclusiveheader, color: "#156DB6" }}>
                      Dimensions of Ad / Content Needed
                    </Typography>
                    <Box sx={{ pt: "0.2%" }}>
                      <Typography sx={dots}>
                        {GetProductByIdData?.Dimensions}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ borderTop: "0.2px solid #156DB6", mt: 2 }}>
                <Box sx={{ mt: 2 }}>
                  <Typography sx={{ ...pack, color: "#156DB6" }}>
                    Key Features
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Grid
                      container
                      mt={2}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      {ProductFeatures?.map((res) => {
                        return (
                          <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                            <Box
                              sx={{
                                display: "flex",
                                textAlign: "start",
                                flexDirection: "row",
                                gap: "100px",
                                mt: 1,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  gap: "20px",
                                  width: "100%",
                                }}
                              >
                                <FeatureName name={res?.name} />
                                <Box
                                  sx={{
                                    width: "80%",
                                    maxWidth: "825px",
                                    height: "auto",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  <Typography sx={packHead}>
                                    {res.name}
                                  </Typography>
                                  <Typography sx={packVal}>
                                    {res.description}{" "}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={{ ...semi, color: "#156DB6" }}>
                    {/* {GetProductByIdData?.ProductName} */}
                    Product Information
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      mx: "auto",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "5px",
                    }}
                  >
                    <DiscountedPrice
                      regularPrice={
                        GetProductByIdData?.ProductsVariantions?.at(0)
                          ?.PricePerUnit
                      }
                      discountPrice={
                        GetProductByIdData?.ProductsVariantions?.at(0)
                          ?.DiscountedPrice
                      }
                      percentage={
                        GetProductByIdData?.ProductsVariantions?.at(0)?.GST
                      }
                      GetProductByIdData={GetProductByIdData}
                      // regularPrice={10000}
                      // discountPrice={5000}
                    />
                  </Box>

                  <Box
                    mt={4}
                    sx={{
                      width: "100%",
                      mx: "auto",
                    }}
                  >
                    <Grid container sx={{ width: "90%" }}>
                      {GetProductByIdData?.medianame ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>Brand Name</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.medianame}
                          </Typography>
                        </Grid>
                      ) : null}
                      {GetProductByIdData?.multiplexScreenName ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>
                            Multiplex Name
                          </Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.multiplexScreenName}
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        {GetProductByIdData?.offerningbrandat ? (
                          <>
                            <Typography sx={tableHeader}>
                              {" "}
                              Offering At
                            </Typography>
                            <Typography
                              sx={{ ...fetchValue, whiteSpace: "nowrap" }}
                            >
                              {GetProductByIdData?.offerningbrandat}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={tableHeader}>
                              {" "}
                              Position of the Ad ?
                            </Typography>
                            <Typography
                              sx={{
                                ...fetchValue,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {GetProductByIdData?.adPosition}
                            </Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 4, width: "90%" }}>
                      {GetProductByIdData?.ProductsVariantions.at(0)
                        ?.location ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>
                            Media Location
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.location
                            }
                          </Typography>
                        </Grid>
                      ) : null}
                      {GetProductByIdData?.ProductsVariantions.at(0)?.Type ? (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>Type</Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.Type
                            }
                          </Typography>
                        </Grid>
                      ) : null}
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        {GetProductByIdData?.ProductsVariantions.at(0)?.unit ? (
                          <>
                            <Typography sx={tableHeader}>Unit</Typography>
                            <Typography sx={fetchValue}>
                              Per{" "}
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.unit
                              }
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={tableHeader}>
                              Release Details
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.releasedetails
                              }
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.Timeline ? (
                          <>
                            <Typography sx={tableHeader}> Timeline</Typography>
                            <Typography sx={fetchValue}>
                              Per{" "}
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.Timeline
                              }
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={tableHeader}> Edition</Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.edition
                              }
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        {GetProductByIdData?.ProductsVariantions.at(0)
                          ?.repetition ? (
                          <>
                            <Typography sx={tableHeader}>
                              {" "}
                              Repetition
                            </Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.repetition
                              }
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Typography sx={tableHeader}>Ad Type</Typography>
                            <Typography sx={fetchValue}>
                              {
                                GetProductByIdData?.ProductsVariantions.at(0)
                                  ?.adType
                              }
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>Dimension Size</Typography>
                        <Typography sx={fetchValue}>
                          {
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.dimensionSize
                          }
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>GST</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.ProductsVariantions.at(0)?.GST} %
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 5, width: "90%" }}>
                      <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                        <Typography sx={tableHeader}>Region</Typography>
                        <Typography sx={fetchValue}>
                          {GetProductByIdData?.GeographicalData?.region}
                        </Typography>
                      </Grid>
                      {GetProductByIdData?.GeographicalData?.state ===
                        undefined ||
                      GetProductByIdData?.GeographicalData?.state === null ||
                      GetProductByIdData?.GeographicalData?.state ===
                        "" ? null : (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}> State</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.GeographicalData?.state}
                          </Typography>
                        </Grid>
                      )}
                      {GetProductByIdData?.GeographicalData?.city ===
                        undefined ||
                      GetProductByIdData?.GeographicalData?.city === null ||
                      GetProductByIdData?.GeographicalData?.city ===
                        "" ? null : (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}>City</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.GeographicalData?.city}
                          </Typography>
                        </Grid>
                      )}
                      {GetProductByIdData?.GeographicalData?.landmark ===
                        undefined ||
                      GetProductByIdData?.GeographicalData?.landmark === null ||
                      GetProductByIdData?.GeographicalData?.landmark ===
                        "" ? null : (
                        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                          <Typography sx={tableHeader}> Landmark</Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.GeographicalData?.landmark}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>

                    <Grid container sx={{ mt: 4, width: "90%" }}>
                      {GetProductByIdData?.ProductsVariantions.at(0)
                        ?.minOrderQuantitytimeline ? (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Min - Max Order Quantity Timeline
                          </Typography>
                          <Typography sx={fetchValue}>
                            {GetProductByIdData?.ProductsVariantions.at(0)
                              ?.minOrderQuantitytimeline
                              ? `${
                                  GetProductByIdData?.ProductsVariantions.at(0)
                                    ?.minOrderQuantitytimeline
                                } - ${
                                  GetProductByIdData?.ProductsVariantions?.at(0)
                                    ?.maxOrderQuantitytimeline
                                }`
                              : "N/A"}{" "}
                            {""} /{" "}
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.Timeline
                            }
                          </Typography>
                        </Grid>
                      ) : null}

                      {GetProductByIdData?.ProductSubCategory ===
                      "643cda0c53068696706e3951" ? null : (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Min - Max Order Quantity Unit
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.minOrderQuantityunit
                            }{" "}
                            -
                            {
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxOrderQuantityunit
                            }
                            /{" "}
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.unit
                            }
                          </Typography>
                        </Grid>
                      )}

                      {GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.minTimeslotSeconds ? (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Min - Max Timeslot
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions.at(0)
                                ?.minTimeslotSeconds
                            }{" "}
                            -
                            {
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxTimeslotSeconds
                            }
                            / Seconds {""}{" "}
                          </Typography>
                        </Grid>
                      ) : null}
                      {GetProductByIdData?.ProductsVariantions?.at(0)
                        ?.seatingCapacity ? (
                        <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                          <Typography sx={tableHeader}>
                            {" "}
                            Seating Capacity
                          </Typography>
                          <Typography sx={fetchValue}>
                            {
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.seatingCapacity
                            }
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>

                  {GetProductByIdData?.OtherCost &&
                  GetProductByIdData?.OtherCost?.length !== 0 ? (
                    <Box mt={3}>
                      <Typography
                        sx={{
                          ...product,
                          fontWeight: 600,
                          fontSize: "18px",
                          lineHeight: "15px",
                          color: "#156DB6",
                        }}
                      >
                        Additional Cost
                      </Typography>
                      {GetProductByIdData?.OtherCost?.length === 0
                        ? ""
                        : GetProductByIdData?.OtherCost?.map((cost) => {
                            const newValue = cost?.CostPrice.toFixed(2);
                            return (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "40px",
                                    mt: 0.5,
                                    border: "none",
                                  }}
                                >
                                  <TableContainer>
                                    <Table
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      <TableBody>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              minWidth: "160px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                ...fetchValue,
                                                color: "grey",
                                              }}
                                            >
                                              {" "}
                                              &#8226; &nbsp;&nbsp;
                                              {cost?.ReasonOfCost}{" "}
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              minWidth: "160px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                ...fetchValue,
                                                color: "grey",
                                              }}
                                            >
                                              HSN - {cost?.AdCostHSN}{" "}
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              minWidth: "160px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                ...fetchValue,
                                                color: "grey",
                                              }}
                                            >
                                              GST - {cost?.AdCostGST} %
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              minWidth: "160px",
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                ...fetchValue,
                                                color: "grey",
                                              }}
                                            >
                                              {cost?.AdCostApplicableOn ===
                                              "All"
                                                ? "One Time Cost"
                                                : "Per Unit"}
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            borderBottom: "none",
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              gap: "5px",
                                              minWidth: "160px",
                                              display: "flex",
                                            }}
                                          >
                                            <Typography>
                                              {cost.currencyType ===
                                              "BXITokens" ? (
                                                <Box
                                                  component="img"
                                                  src={BXITokenIcon}
                                                  alt="token"
                                                  sx={{
                                                    height: "auto",
                                                    width: "15px",
                                                    marginTop: "10px",
                                                  }}
                                                />
                                              ) : (
                                                <Typography
                                                  sx={{
                                                    fontSize: "20px",
                                                    ml: 0.5,
                                                    color: "#156DB6",
                                                    mt: 0.5,
                                                  }}
                                                >
                                                  ₹
                                                </Typography>
                                              )}
                                            </Typography>
                                            <Typography
                                              sx={{
                                                ...fetchValue,
                                                color: "grey",
                                              }}
                                            >
                                              {newValue}
                                            </Typography>
                                          </Box>
                                        </TableCell>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Box>
                              </>
                            );
                          })}
                    </Box>
                  ) : null}
                  {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks
                    ?.length !== 0 ? (
                    <Box sx={{ mt: 1 }}>
                      <Typography sx={{ ...cost, color: "#156DB6" }}>
                        Remarks{" "}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width: "95%",
                          gap: "10px",
                          mt: 0.5,
                        }}
                      >
                        {GetProductByIdData?.OtherInformationBuyerMustKnowOrRemarks?.map(
                          (el, idx) => {
                            return (
                              <>
                                <Typography sx={otherCostText}>
                                  {idx + 1}.
                                </Typography>
                                <Typography sx={otherCostText}>{el}</Typography>
                              </>
                            );
                          }
                        )}
                      </Box>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="3">
              <Box>
                <Typography sx={pack}>Technical Information</Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2px",
                  }}
                >
                  <Typography sx={{ ...inclusiveheader, color: "#156DB6" }}>
                    Supporting you would get from Seller
                  </Typography>
                  {GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                    ? Object?.keys(
                        GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer
                      ).map((el, idx) => {
                        if (
                          GetProductByIdData?.WhatSupportingYouWouldGiveToBuyer[
                            el
                          ] === "on"
                        ) {
                          return (
                            <>
                              <Typography
                                sx={{
                                  ...packHead,
                                  color: "#6B7A99",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                {" "}
                                {el}
                              </Typography>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })
                    : null}{" "}
                </Box>

                <Box>
                  <Typography sx={{ ...inclusiveheader, color: "#156DB6" }}>
                    Dimensions of Ad / Content Needed
                  </Typography>
                  <Box sx={{ pt: "0.2%" }}>
                    <Typography sx={dots}>
                      {GetProductByIdData?.Dimensions}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value="4">
              <Box>
                <Typography sx={pack}>Key Features</Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Grid
                    container
                    mt={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    {ProductFeatures?.map((res) => {
                      return (
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
                          <Box
                            sx={{
                              px: 2,
                              display: "flex",
                              // flexWrap: "wrap",
                              textAlign: "start",
                              flexDirection: "row",
                              gap: "100px",
                              mt: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                gap: "20px",
                                width: "100%",
                              }}
                            >
                              {/* <Box
                                      component="img"
                                      src={bxifeature}
                                      sx={{ height: "80px", width: "30px" }}
                                    /> */}
                              <FeatureName name={res?.name} />
                              <Box
                                sx={{
                                  width: "80%",
                                  maxWidth: "825px",
                                  height: "auto",
                                  wordWrap: "break-word",
                                }}
                              >
                                <Typography sx={packHead}>
                                  {res.name}
                                </Typography>
                                <Typography sx={packVal}>
                                  {res.description}{" "}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={BookButtonStyle}
          >
            <Box>
              {MediaData?.body?.find(
                (item) => item?.ProductId?._id === ProductId
              ) ? (
                <Button
                  variant="contained"
                  sx={CartButtonStyle}
                  onClick={() =>
                    navigate("/home/cart", {
                      state: 1,
                    })
                  }
                >
                  Go To Cart
                </Button>
              ) : (
                addToCardView === false && (
                  <Button
                    variant="contained"
                    sx={CartButtonStyle}
                    onClick={() => {
                      if (singleUnitProduct) {
                        if (boughtDate?.length === 0) {
                          alert("Please Select Date");
                        } else if (
                          (GetProductByIdData?.ProductSubCategory ===
                            "647713dcb530d22fce1f6c36" &&
                            Number(unitsToBebought) <
                              Number(
                                GetProductByIdData?.ProductsVariantions?.at(0)
                                  ?.minOrderQuantitytimeline
                              )) ||
                          Number(unitsToBebought) >
                            Number(
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxOrderQuantitytimeline
                            )
                        ) {
                          alert(
                            "Units Should not be less than or greater than max Qty"
                          );
                        } else {
                          handleAddToCart(ProductId);
                        }
                      } else {
                        if (boughtDate?.length === 0) {
                          alert("Please Select Date");
                        } else if (unitsToBebought === 0) {
                          alert("Please Select Units");
                        } else if (
                          Number(unitsToBebought) >
                          Number(
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.maxOrderQuantityunit -
                              GetProductByIdData?.Inventory?.Quantity
                          )
                        ) {
                          alert("Units Should not be more than max Qty");
                        } else if (
                          Number(unitsToBebought) <
                          Number(
                            GetProductByIdData?.ProductsVariantions.at(0)
                              ?.minOrderQuantityunit
                          )
                        ) {
                          alert("Units Should not be less than min Qty");
                        } else if (
                          GetProductByIdData?.ProductSubCategory ===
                            "65029534eaa5251874e8c6b4" &&
                          Number(unitsToBebought) !==
                            Number(
                              GetProductByIdData?.ProductsVariantions?.at(0)
                                ?.maxOrderQuantityunit
                            )
                        ) {
                          alert(
                            "Units Should not be less than or greater than max Qty"
                          );
                        } else {
                          handleAddToCart(ProductId);
                        }
                      }
                    }}
                  >
                    Add to cart
                  </Button>
                )
              )}
            </Box>
          </Grid>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

const CloseButtonStyle = {
  borderRadius: "20px",
  background: "#156DB6",
  color: "#fff",
  mt: 1,
  p: "03px",
  textTransform: "none",
  fontSize: "12px",
  width: "20px",
  height: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

const inclusiveheader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xs: "12px",
    sm: "15px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  color: "#6B7A99",
  width: {
    xl: "80%",
    lg: "80%",
    md: "65%",
    sm: "100%",
    xs: "100%",
  },
  pt: "1%",
};

const otherCostText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  color: "grey",
};

const cost = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  color: "#6B7A99",
};

const dots = {
  display: "flex",
  gap: "8px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xs: "20px",
    sm: "15px",
    md: "16px",
    lg: "16px",
    xl: "16px",
  },
  textAlign: "justify",
  color: "grey",
};

const pack = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2.1rem",
    lg: "2.1rem",
    md: "2rem",
    sm: "2rem",
    xs: "2rem",
  },
  textAlign: {
    xl: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
  color: "#156DB6",
};
const fetchValue = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 450,
  fontSize: "14px",
  color: "grey",
  marginTop: "7px",
};
const packHead = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "#6B7A99",
};

const packVal = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.2rem",
    xs: "1.2rem",
  },
  color: "grey",
};

const tableData = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "1.4rem",
    lg: "1.4rem",
    md: "1.6rem",
    sm: "1.6rem",
    xs: "1.5rem",
  },
  color: "#B1B1B1",
  lineHeight: "4rem",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const tableHeader = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 550,
  fontSize: {
    xl: "1.6rem",
    lg: "1.6rem",
    md: "1.6rem",
    sm: "1.4rem",
    xs: "1rem",
  },
  color: "#6B7A99",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const TypographyTitleText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "18px",
    lg: "18px",
    md: "18px",
    sm: "16px",
    xs: "16px",
  },
  color: "#156DB6",
  // py: "8px",
};

const DescriptionAnswerText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "16px",
    lg: "16px",
    md: "12px",
    sm: "10px",
    xs: "8px",
  },
  textAlign: "justify",
  color: "#6B7A99",
};

const BookButtonStyle = {
  textAlign: "center",
};

const CartButtonStyle = {
  width: "90%",
  fontSize: {
    xl: "16px",
    lg: "16px",
    md: "12px",
    sm: "10px",
    xs: "8px",
  },
  fontFamily: "poppins",
  fontStyle: "normal",
  fontWeight: 500,
  background: "#445FD2",
  ":hover": {
    background: "#445FD2",
  },
  textTransform: "none",
  position: "sticky",
  bottom: "-25px",
  left: "0px",
  zIndex: 999,
};

const semi = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2.2rem",
    sm: "2rem",
    xs: "2rem",
  },
  color: "#156DB6",
  textAlign: {
    x: "start",
    lg: "start",
    md: "start",
    sm: "start",
    xs: "center",
  },
};

const selectdate = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};
const product = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: {
    xl: "2.4rem",
    lg: "2.4rem",
    md: "2.2rem",
    sm: "2.2rem",
    xs: "2.1rem",
  },
  color: "#6B7A99",
};
