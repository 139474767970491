import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import GoLeft from "../../../../assets/Images/CommonImages/GoLeft.png";
import BulkUploadImg from "../../../../assets/Images/CommonImages/bulkUploadImg.svg";
import { useNavigate } from "react-router-dom";
import LifestyleExcelSheet from "../../../../assets/ExelSheets/Zip2/Lifestyle_Category.zip";
import Dowmwhite from "../../../../assets/download (3).svg";
import uploadwhite from "../../../../assets/upload (1).svg";
import { useRef } from "react";
import stepstobulkupload from "../../../../assets/Bulk_upload_steps.jpg";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import avatar from "../../../../assets/Images/CommonImages/Companylogo (2).svg";
import CircularProgress from "@mui/material/CircularProgress";
import Avatargenerator from "../../../../components/AvatarGenerator.jsx";
import downloadicon from "../../../../assets/download (1).svg";
import uploadicon from "../../../../assets/upload.svg";
import downlgrey from "../../../../assets/download (2).svg";
import filesave from "../../../../assets/file-save.svg";
import folderdwn from "../../../../assets/folder-download (1).svg";
import deleteicon from "../../../../assets/delete.svg";
import useGetLoggedInUser from "../../../../Hooks/LoggedInUser/useGetLoggedInUser.js";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";
import ShadowBox from "../../../../views/LandingPage/ShadowBox.jsx";
import {
  BulkuploadproductToDB,
  loading,
} from "../../../../redux/action/Products/BulkuploadproductToDB.js";
import { useSelector, useDispatch } from "react-redux";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const useStyles = makeStyles({
  tableRow: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
});

const LifestyleBulkUploadPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [upload, setUpload] = useState(null);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [storeCompanyType, setStoreCompanyType] = useState("");

  const dispatch = useDispatch();

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const myForm = new FormData();
    myForm.append("file", file);

    // Optional: Use FileReader only if you want to preview the file
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      setUpload(data); // This assumes you're setting some preview of the file data
    };

    reader.onerror = (err) => {
      console.error("Error reading the file:", err);
    };

    // If you don't need to read the file content before uploading, you can skip the FileReader part
    reader?.readAsArrayBuffer(file);

    // Upload the file
    uploadExcelFile(myForm);
  };

  const uploadExcelFile = async (myForm) => {
    try {
      const { data } = await axios.post(
        "publiq_bulk_upload/bulk_upload",
        myForm,
        {
          headers: { "Content-type": "multipart/form-data" },
          withCredentials: true,
        }
      );

      setData(data);
      setLoading(true);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.name, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
  } = useGetLoggedInUser();

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  useEffect(() => {
    const companyType = localStorage.getItem("companyType");
    setStoreCompanyType(companyType);
    console.log("companyType", companyType);
  }, []);

  console.log("storeCompanyType", storeCompanyType);

  return (
    <>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />
      <Paper
        sx={{ width: "100%", height: "70vh", position: "relative" }}
        elevation={0}
      >
        <Container
          sx={{
            display: "grid",
            width: "650px",
            maxWidth: "650px",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            textAlign: "center",
            gap: "15px",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Upload Excel File
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 500,
                color: "#444444",
                fontFamily: "Poppins",
              }}
            >
              Select Category
            </Typography>
            <FormControl fullWidth margin="normal" sx={{ maxWidth: "400px" }}>
              <select
                labelId="category-label"
                value={category}
                label="Category"
                onChange={handleCategoryChange}
                style={{
                  border: "1px solid lightgray",
                  height: "40px",
                  width: "100%",
                  padding: "10px",
                }}
              >
                {storeCompanyType ? (
                  <option
                    value={storeCompanyType}
                    style={{
                      padding: "10px",
                      height: "35px",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6">{storeCompanyType}</Typography>
                  </option>
                ) : null}
              </select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                fontWeight: 500,
                color: "#444444",
                fontFamily: "Poppins",
              }}
            >
              Choose you Excel File{" "}
            </Typography>
            <input
              type="file"
              ref={inputFile}
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              onClick={onButtonClick}
              sx={{
                height: "40px",
                fontSize: "14px",
                textTransform: "none",
                width: "400px",
                background: "#c64091",
                "&:hover": {
                  background: "#fff",
                  color: "#c64091",
                  cursor: "pointer",
                  border: "1px solid #c64091",
                },
              }}
            >
              Add file <FileUploadIcon fontSize="large" />
            </Button>
          </Box>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => console.log("Create Template")}
            sx={{
              marginTop: "10px",
              height: "40px",
              fontSize: "14px",
              background: "#c64091",
              textTransform: "none",
              "&:hover": {
                background: "#fff",
                color: "#c64091",
                cursor: "pointer",
                border: "1px solid #c64091",
              },
            }}
          >
            Create Template
          </Button>
          {loading && <p>Loading...</p>}
          {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
        </Container>
      </Paper>
    </>
  );
};

export default LifestyleBulkUploadPage;
const sellerBoxDesign = {
  width: "82px",
  height: "84px",
  position: "absolute",
  top: "-6px",
  boxShadow: "0px -5px 5px 0px rgba(0, 0, 0, 0.2)",
  left: "-2px",
  borderRadius: "20px",
  display: "flex",
  marginTop: "0px",
  // border:"1px solid black"
};

const noteText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "28px",
  display: "flex",
  alignItems: "center",
  marginTop: "3rem",
  color: "#6B7A99",
  width: "80%",
};
const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.2rem",
  color: "#929EAE",
};
const rectangleStyle = {
  width: "300px", // Set your desired width
  height: "70px", // Set your desired height
  backgroundColor: "white",
  borderRadius: "10px", // Set your desired border radius
  position: "relative",
  boxShadow:
    "0px -5px 5px 0px rgba(0, 0, 0, 0.2), 5px 0px 5px 0px rgba(0, 0, 0, 0.2)", // Add upper and right shadows
};

const filterbutton = {
  // color: "#1B212D",
  // border: "1px solid #F5F5F5",
  // borderRadius: "10px",
  width: " 281px",
  height: "48px",
  ml: "auto",
  background: "#445FD2",
  borderRedius: "10px",
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "none",
};

const Upload = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "18px",
  alignItems: "center",
  textAlign: "center",
  textDecoration: "none",
  color: "#445FD2",
  cursor: "pointer",
};
