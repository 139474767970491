// import React from "react";
// const Bulkuploadpage = () => {
//   return (
//     <>
//       <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />
//     </>
//   );
// };

// export default Bulkuploadpage;
import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  FormControl,
} from "@mui/material";
import GoLeft from "../../../../assets/Images/CommonImages/GoLeft.png";
import BulkUploadImg from "../../../../assets/Images/CommonImages/bulkUploadImg.svg";
import { useNavigate } from "react-router-dom";
import MediaOfflineExcelSheet from "../../../../assets/ExelSheets/Media/OFF_Media_Category_-_NewsPaper.zip";
import MediaOfflineNewspaper from "../../../../assets/ExelSheets/Media_Offline_Final_-_NewsPaper.xlsx";
import downloadicon from "../../../../assets/download (1).svg";
import stepstobulkupload from "../../../../assets/Bulk_upload_steps.jpg";
import uploadicon from "../../../../assets/upload.svg";
import downlgrey from "../../../../assets/download (2).svg";
import filesave from "../../../../assets/file-save.svg";
import folderdwn from "../../../../assets/folder-download (1).svg";
import deleteicon from "../../../../assets/delete.svg";
import { useRef } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import Dowmwhite from "../../../../assets/download (3).svg";
import uploadwhite from "../../../../assets/upload (1).svg";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import avatar from "../../../../assets/Images/CommonImages/Companylogo (2).svg";
import CircularProgress from "@mui/material/CircularProgress";
import Avatargenerator from "../../../../components/AvatarGenerator.jsx";
import useGetLoggedInUser from "../../../../Hooks/LoggedInUser/useGetLoggedInUser.js";
import { toast, ToastContainer } from "react-toastify";
import { makeStyles } from "@mui/styles";

import {
  MediaBulkuploadproductToDB,
  loading,
} from "../../../../redux/action/Products/MediaBulkuploadproductToDB.js";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles({
  tableRow: {
    border: "1px solid rgba(224, 224, 224, 1)", // Change color as needed
  },
});

const MediaOffline = [
  {
    key: 1,
    value: "Offline Media Output File - Newspaper/Magazines",
    name: "News Papers / Magazines",
  },
  {
    key: 2,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Gantry Gate",
  },
  {
    key: 3,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Hoardings",
  },
  {
    key: 4,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Skywalk",
  },
  {
    key: 5,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Wall Branding",
  },
  {
    key: 6,
    value: "Offline Media Output File - Others",
    name: "Activation",
  },
  {
    key: 7,
    value: "Offline Media Output File - Others",
    name: "Ambient Media",
  },
  {
    key: 8,
    value: "Offline Media Output File - Others",
    name: "Banners",
  },
  {
    key: 9,
    value: "Offline Media Output File - Others",
    name: "Boarding Pass",
  },
  {
    key: 10,
    value: "Offline Media Output File - Others",
    name: "Bus Handles",
  },
  {
    key: 11,
    value: "Offline Media Output File - Others",
    name: "Bus Shelters",
  },
  {
    key: 12,
    value: "Offline Media Output File - Others",
    name: "Dangler",
  },
  {
    key: 13,
    value: "Offline Media Output File - Others",
    name: "Door Handles",
  },
  {
    key: 14,
    value: "Offline Media Output File - Others",
    name: "Drop Down Banners",
  },
  {
    key: 15,
    value: "Offline Media Output File - Others",
    name: "Elevator Branding",
  },
  {
    key: 16,
    value: "Offline Media Output File - Others",
    name: "Escalator Branding",
  },
];

const MediaOfflineBulkUploadPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [upload, setUpload] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offlineCategory, setOfflineCategory] = useState("");
  const [storeCompanyType, setStoreCompanyType] = useState("");

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Check if a file is selected
    if (!file) {
      return;
    }

    const myForm = new FormData();
    myForm.append("file", file);

    // Optional: Use FileReader only if you want to preview the file
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      setUpload(data); // This assumes you're setting some preview of the file data
    };

    reader.onerror = (err) => {
      console.error("Error reading the file:", err);
    };

    // If you don't need to read the file content before uploading, you can skip the FileReader part
    reader?.readAsArrayBuffer(file);

    // Upload the file
    uploadExcelFile(file);
  };

  const uploadExcelFile = async (myForm) => {
    try {
      const formData = new FormData();
      formData.append("file", myForm);
      formData.append("data", onlineCategory);
      const { data } = await axios.post(
        "publiq_bulk_upload/bulk_upload",
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      setData(data);
      setLoading(true);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.name, {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  const handleMediaOfflineCategoryChange = (event) => {
    console.log("Offline", event.target.value);
    setOfflineCategory(event.target.value);
  };

  useEffect(() => {
    const companyType = localStorage.getItem("companyType");
    setStoreCompanyType(companyType);
  }, []);

  return (
    <>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />

      <Box
        sx={{
          width: "100%",
          maxWidth: "700px",
          mx: "auto",
        }}
      >
        <Typography>Media Offline</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 500,
              color: "#444444",
              fontFamily: "Poppins",
            }}
          >
            Select Category
          </Typography>
          <FormControl fullWidth margin="normal" sx={{ maxWidth: "400px" }}>
            <select
              labelId="category-label"
              value={offlineCategory}
              label="Category"
              onChange={handleMediaOfflineCategoryChange}
              style={{
                border: "1px solid lightgray",
                height: "40px",
                width: "100%",
                padding: "10px",
              }}
            >
              {MediaOffline?.map((res, idx) => {
                return (
                  <option
                    key={idx}
                    value={res?.value}
                    style={{
                      padding: "10px",
                      height: "35px",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h6">{res?.name}</Typography>
                  </option>
                );
              })}
            </select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "15px",
              fontWeight: 500,
              color: "#444444",
              fontFamily: "Poppins",
            }}
          >
            Choose you Excel File{" "}
          </Typography>
          <input
            type="file"
            ref={inputFile}
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          <Button
            variant="contained"
            onClick={onButtonClick}
            sx={{
              height: "40px",
              fontSize: "14px",
              textTransform: "none",
              width: "400px",
              background: "#c64091",
              "&:hover": {
                background: "#fff",
                color: "#c64091",
                cursor: "pointer",
                border: "1px solid #c64091",
              },
            }}
          >
            Add file <FileUploadIcon fontSize="large" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default MediaOfflineBulkUploadPage;
const buttonStyle = {
  backgroundColor: "#445FD2",
  fontFamily: "Poppins",
  fontSize: "12px",
  width: "300px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
  textTransform: "none",
  color: "white",
  border: "none",
  borderRadius: "10px", // Adjust the value to control the roundness of the corners
  padding: "10px 20px",
  cursor: "pointer",
};
const mainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "27px",
  color: "#6B7A99",
};
const MetaTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "24px",
  textAlign: "justify",
  color: "#6B7A99",
  marginTop: "2rem",
};
const pointsStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 18,
  lineHeight: "122.5%",
  display: "flex",
  alignItems: "center",
  color: "#6B7A99",
  marginTop: "1.5rem",
};
const downLoadText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: 22,
  lineHeight: "18px",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  textDecoration: "none",
  color: "#445FD2",
};
const noteText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 16,
  lineHeight: "28px",
  display: "flex",
  alignItems: "center",
  marginTop: "3rem",
  color: "#6B7A99",
  width: "80%",
};
const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "1.2rem",
  color: "#929EAE",
};

const filterbutton = {
  // color: "#1B212D",
  // border: "1px solid #F5F5F5",
  // borderRadius: "10px",
  width: " 281px",
  height: "48px",
  ml: "auto",
  background: "#445FD2",
  borderRedius: "10px",
  color: "#FFFFFF",
  fontFamily: "Poppins",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textTransform: "none",
};

const Upload = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "18px",
  alignItems: "center",
  textAlign: "center",
  textDecoration: "none",
  color: "#445FD2",
  cursor: "pointer",
};
