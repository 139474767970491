import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import axios from "axios";
import BasicMembership from "./BasicMembership";
import AdvanceMemvershipInvoice from "./AdvanceMemvershipInvoice";
import state_code from "../../utils/state_code.json";

function removeGST(amountIncludingGST, gstRate) {
  return amountIncludingGST / (1 + gstRate / 100);
}

const MembershipFeesInvoice = () => {
  const [StoreMemberShipData, setMemberShipData] = useState();
  const [MemberShipPlanName, setMemberShipPlanName] = useState();
  const [amountWithoutGST, setAmountWithoutGST] = useState(0);
  const [gst, setGST] = useState(0);

  async function GetmemberShipData() {
    await axios.get("just-pay/get-membership-data").then((res) => {
      setMemberShipData(res?.data);
      handleSubmitBasic(res?.data);
    });
  }

  const handleSubmitBasic = async (membership) => {
    await axios
      .get(
        `membership/get-membership-plan-name?type=${membership?.data?.MembershipType}`
      )
      .then((res) => {
        setMemberShipPlanName(res?.data?.PlanName);
      });
  };

  useEffect(() => {
    GetmemberShipData();
  }, []);

  useEffect(() => {
    if (StoreMemberShipData?.data?.MembershipAmount) {
      const amount = removeGST(StoreMemberShipData?.data?.MembershipAmount, 18);
      setAmountWithoutGST(amount);
      setGST(StoreMemberShipData?.data?.MembershipAmount - amount);
    }
  }, [StoreMemberShipData]);

  useEffect(() => {
    try {
      state_code?.map((res) => {
        if (
          res?.name?.toLowerCase() ==
          StoreMemberShipData?.data?.CompanyAddress?.State.toLowerCase()
        ) {
          StateCode = res?.tin;
        }
      });
    } catch (e) {
      return e;
    }
  }, []);

  let MemeberShipData = {
    MembershipAmount: amountWithoutGST,
    MembershipGST: gst,
    IRN: StoreMemberShipData?.data?.IRN,
    HSN: 999599,
    GSTPercentage: 18,
    MemberShipPlanName: MemberShipPlanName,
    TotalAmount: StoreMemberShipData?.data?.MembershipAmount,
    InvoiceDate: StoreMemberShipData?.data?.MembershipStartDate,
    MembershipId: StoreMemberShipData?.data?.MembershipId,
    MembershipType: StoreMemberShipData?.data?.MembershipType,
    CompanyAddress: StoreMemberShipData?.data.CompanyAddress,
    StateCode: state_code.find(
      (res) =>
        res?.name?.toLowerCase() ===
        StoreMemberShipData?.data?.CompanyAddress?.State.toLowerCase()
    )?.tin,
    IGST:
      StoreMemberShipData?.data?.CompanyAddress?.State?.toLowerCase() ===
      "maharashtra"
        ? false
        : true,
  };

  return (
    <Paper sx={{ width: "100%", bgcolor: "transparent" }} elevation={0}>
      <BreadCrumbHeader
        MainText="BXI Fees"
        LinkText1="{splitedurl[1]}"
        LinkText2="{splitedurl[2]}"
        link1="Link1"
        link2="link2"
        showbreadcrumb={true}
      />
      <AdvanceMemvershipInvoice MemberShipData={MemeberShipData} />
    </Paper>
  );
};

export default MembershipFeesInvoice;
